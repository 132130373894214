import React, { useState } from 'react'
// import {} from 'antd'
import {ReactComponent as ModesIcon} from '../../assets/modes.svg';
import {ReactComponent as DarkIcon} from '../../assets/Dark-Mode.svg';
import * as DarkReader from 'darkreader';

import {
    enable as enableDarkMode,
    disable as disableDarkMode,
} from 'darkreader';
import { useDispatch } from 'react-redux';
import { slideAction } from '../../store/slideSlice';




function Modes() {
  const isMobile =window.screen.width<768
  DarkReader.setFetchMethod(window.fetch);

    const [darkMode,setDarkMode]=useState(true)
    const dispatch=useDispatch()
    // console.log('darkMode : Out ',darkMode)

    const setMode=()=>{

        // console.log('darkMode : ',darkMode)
        // alert('Dark Mode')
        if(darkMode){
            enableDarkMode({
                brightness: 100,
                contrast: 90,
                sepia: 10,
            });
        }else{
            disableDarkMode();

        }
        setDarkMode(!darkMode)
        dispatch(slideAction.setDarkMode(!darkMode))
    }
  return (
    <div>
            <div onClick={setMode} style={{ marginTop: "5px", cursor: "pointer", zIndex: 1,opacity:.95 ,}} >
            {darkMode==!true ?
              <ModesIcon style={{width:isMobile?'20px': "26px",height:isMobile?'20px': "26px" }}/>:
              <DarkIcon style={{width:isMobile?'20px': "28px",height:isMobile?'20px': "28px" }}/>}
            </div>
    </div>
  )
}

export default Modes