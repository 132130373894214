import {io} from 'socket.io-client';
import { slideAction } from '../store/slideSlice';

// const serverURL = "https://mileslides.konnect.im/";
const serverURL = "https://slides.workw.com/";
// const serverURL = "http://localhost:8080/";
 // const serverURL = "http://192.168.18.123:8080/";
const socket = io(serverURL);

// socket emitters
export const emitRoomID = (roomID) => {
    socket.emit("room-id",roomID);
  }

// Emitters for Chat Implemented here
export const emitLiveComments = (data) => {
    socket.emit("comments", data);
  };

//Emits mouse coordinates to the server.
export const emitMousePositions = (obj) => {
  if(obj.userName){
    socket.emit("mouse-positions", obj);}
  };

export const emitSlideAdd = (data) =>{
    socket.emit("add-slide", data);
}

export const emitElementAdd = (data) =>{
    socket.emit("add-element", data);
}

export const emitTextUpdate = (data) =>{
    socket.emit("update-text", data);
}
export const emitShapeTextUpdate = (data) =>{
    socket.emit("update-shape-text", data);
}

export const emitStyleUpdate = (data) =>{
    socket.emit("update-style",data);
}

export const emitElementDelete = (data) =>{
    socket.emit("delete-element", data);
}

export const emitSlideDelete = (data) =>{
    socket.emit("delete-slide", data);
}
export const emitSlideColor = (data) =>{
    socket.emit("slide-color", data);
}
export const emitSpeakerNotes = (data) =>{
    socket.emit("speaker-notes", data);
}


// socket listeners

//   socket.on("add-slide",(data)=>{
    //     const {roomId, slideData} = data
    //     socket.to(roomId).emit("add-slide", slideData);
    //   })
    
    export const listenSlideAdd = (dispatch) =>{
        socket.on("add-slide",(data)=>{
            const { slideData, mirrorData } = data;
          dispatch(slideAction.addSlide(slideData)); 
          dispatch(slideAction.saveMirror(mirrorData)); 
        });
    }
    export const listenElementAdd = (dispatch) =>{
        socket.on("add-element",(data)=>{
            const {elementType, elementData} = data;
            if (elementType === "text") {
                // console.log("Inside TEXT Add LISTENER", elementData);
                dispatch(slideAction.addTextBox(elementData));
              } else if (elementType === "image") {
                dispatch(slideAction.addImage(elementData));
              } else if (elementType === "shape") {
                dispatch(slideAction.addShape(elementData));
              }
        });
    }
    
    export const listenTextUpdate = (dispatch) =>{
        socket.on("update-text", (data) => {
            const {elementData, mirrorData} = data;
            dispatch(slideAction.updateDelta(elementData));
            dispatch(slideAction.saveMirror(mirrorData));
        })
    }
    export const listenShapeTextUpdate = (dispatch) =>{
        socket.on("update-shape-text", (data)=>{
            const {elementData, mirrorData} = data;
            dispatch(slideAction.updateShapeText(elementData));
            dispatch(slideAction.saveMirror(mirrorData));
        });
    }
    
    export const listenStyleUpdate = (dispatch) =>{
        socket.on("update-style", (elementData) => {
            dispatch(slideAction.updateStyle(elementData));
        });
    }
    
    export const listenElementDelete = (dispatch) =>{
        socket.on("delete-element", (data) => {
            const {elementType, elementData} = data;
            if (elementType === "text") {
                dispatch(slideAction.removeTextBox(elementData));
              } else if (elementType === "image") {
                dispatch(slideAction.removeImage(elementData));
              } else if (elementType === "shape") {
                dispatch(slideAction.removeShape(elementData));
              }
        });
    }
    
    export const listenSlideDelete = (dispatch) =>{
        socket.on("delete-slide", (slideId) => {
            dispatch(slideAction.removeSlide(slideId));
        });
    }

    export const listenSlideColor = (dispatch) => {
        socket.on("slide-color",(data)=>{
            const {slideData, mirrorData} = data
            dispatch(slideAction.setSlideBackgroundColor(slideData));
            dispatch(slideAction.saveMirror(mirrorData));
        });
    }
    export const listenSpeakerNotes = (dispatch) => {
        socket.on("speaker-notes",(notesData)=>{
            const {slideId, content} = notesData;
            dispatch(slideAction.addSpeakerNotes({slideId, content}));
        });
    }
    // //Listener for Chats
    export const listenerLiveComments = (updateMessageList) => {
    socket.on("comments", (data) => {
      data.color = "#98b3e6";
      data.margin = "5px auto 5px 0px"; //this styling work should be done through id
      updateMessageList(data);
    
    });
  };

  export const addTotalClients = (setNumberOfConnectedClients) => {
    socket.on("total-clients", (totalClients) => {
      setNumberOfConnectedClients(totalClients);
    });
  };
  
  export const connectedClients = (setConnectedSocketID) => {
    socket.on("connected-clients-ids", (connectedClientArr) => {
      // console.log(connectedClientArr);
      setConnectedSocketID(connectedClientArr);
    });
  };
  
  export const removeTotalClients = (setNumberOfConnectedClients) => {
    socket.on("disonnected-client", (totalClients) => {
      setNumberOfConnectedClients(totalClients);
    });
  };
//Listener For Mouse Positions 
export const renderMouse = (setConnectedSocketID) => {
  socket.on("mouse-positions", (info) => {
    setConnectedSocketID(info);
     });
};
export default socket;
