import { Menu, Dropdown, message, Button } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { slideAction } from "../../store/slideSlice";
import presentIcon from "../../assets/presentIcon.svg"

export const Present = () => {
  const isMobile=window.screen.width<768

  const currentSlideId = useSelector(state => state.slide.currentSlideId);
  const slides = useSelector(state => state.slide.slides);
  const darkMode=useSelector(state=>state.slide.darkMode)


  const dispatch = useDispatch();
  const slideIds = useMemo(() => Object.keys(slides).filter((slideId) => !slideId.includes('master')), [slides]);

  let currentIndex = slideIds.indexOf(currentSlideId);
 
  // const [prevTime, setPrevTime] = useState("");
  
  // document.onfullscreenchange = function ( event ) {
  //   if(event.timeStamp > prevTime){
  //     window.removeEventListener('keydown', changeSlide, false);
  //   }else{
  //     setPrevTime(event.timeStamp);
  //   }
  // };
// useEffect(()=> {
//   document.onfullscreenchange = function(event){
//     if(prevTime && prevTime < event.timeStamp){
//       window.removeEventListener('keydown',changeSlide,{once: true});
//       setPrevTime("");
//     }
//   }
// }, [prevTime])
  

 function changeSlide(e){
    if(e.key==='ArrowDown'){
      dispatch(slideAction.setCurrentSlide(slideIds[currentIndex === slideIds.length-1 ? slideIds.length-1 : ++currentIndex]))
    }else if(e.key==='ArrowRight'){
      dispatch(slideAction.setCurrentSlide(slideIds[currentIndex === slideIds.length-1 ? slideIds.length-1 : ++currentIndex]))
    }else if(e.key==='ArrowUp'){
      dispatch(slideAction.setCurrentSlide(slideIds[currentIndex === 0 ? 0 : --currentIndex]))
    }else if(e.key==='ArrowLeft'){
      dispatch(slideAction.setCurrentSlide(slideIds[currentIndex === 0 ? 0 : --currentIndex]))
    }
 }


  

function exitHandler()
{
  if (!document.webkitIsFullScreen && !document.mozFullScreenElement &&
    !document.webkitFullscreenElement && !document.msFullscreenElement)
 {
  let slideContainer = document.getElementById('slide01');
  slideContainer.classList.remove('zoom');
  document.removeEventListener('keydown', changeSlide, false);
  dispatch(slideAction.setPresentMode(false));
  document.removeEventListener('fullscreenchange', exitHandler, false);
  document.removeEventListener('mozfullscreenchange', exitHandler, false);
  document.removeEventListener('MSFullscreenChange', exitHandler, false);
  document.removeEventListener('webkitfullscreenchange', exitHandler, false);
}
}

  function handleButtonClick(e) {
    let element = document.getElementById('slide01');

    if(element){
      element.classList.add('zoom');
      element.requestFullscreen().then(() => {
      // document.onfullscreenchange = function ( event ) {
          document.addEventListener('keydown', changeSlide,false);
          element.childNodes.forEach((ele)=>{
            ele.classList.contains('border') && ele.classList.remove('border') ;
            ele.classList.contains('selected') && ele.classList.remove('selected')
          
          });
          dispatch(slideAction.setPresentMode(true));

          document.addEventListener('fullscreenchange', exitHandler, false);
          document.addEventListener('mozfullscreenchange', exitHandler, false);
          document.addEventListener('MSFullscreenChange', exitHandler, false);
          document.addEventListener('webkitfullscreenchange', exitHandler, false);
          // if(event.timeStamp > prevTime){
          //   window.removeEventListener('keydown', changeSlide, false);
          // }else{
          //   setPrevTime(event.timeStamp);
          // }
        // };
      })
      const toolbar = document.getElementById("quillToolbar").firstElementChild;
      toolbar && toolbar.remove();  // if toolbar is active then remove it
    }
  }

  function handleMenuClick(e) {
    message.info("Click on menu item.");
  }

  // const menu = (
  //   <Menu onClick={handleMenuClick}>
  //     <Menu.Item key="1" icon={<UserOutlined />}>
  //       1st menu item
  //     </Menu.Item>
  //     <Menu.Item key="2" icon={<UserOutlined />}>
  //       2nd menu item
  //     </Menu.Item>
  //     <Menu.Item key="3" icon={<UserOutlined />}>
  //       3rd menu item
  //     </Menu.Item>
  //   </Menu>
  // );

  return (
    <Button style={{width:isMobile?'70px':'100px',height: '30px', textAlign: "center",lineHeight: 0.8, verticalAlign: 'center', color: "#0f0f0a"}} onClick={handleButtonClick}>
      <span style={{fontSize:isMobile?'10px': '12px',fontWeight: 500, letterSpacing: 0.5,marginLeft:isMobile?-8:'', paddingRight:'5px'}}>Present</span><span className="present-icon"><img width={'16px'} height={'20px'} src={presentIcon} style={{filter:darkMode?'none':'invert(85%)'}} alt="..." /></span>
    </Button>
  );
};