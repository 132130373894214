import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { slideAction } from '../../../store/slideSlice';

const SidebarSlideView = ({currentSlideId, slideId,mirrorSlides, ind}) => {
    const dispatch = useDispatch();
    const darkMode=useSelector(state=>state.slide.darkMode)
  const slideBackgroundColor = useSelector((state) => currentSlideId && state.slide.slides[slideId]?.slideBackgroundColor);
// console.log('slideBackgroundColor SideBar',slideBackgroundColor);
    return ( <div className={currentSlideId === slideId ? "sider-slide-container selectedSlide" : "sider-slide-container"}  key={slideId} onClick={() => dispatch(slideAction.setCurrentSlide(slideId))}>
                <span style={{fontSize: '16px', alignSelf: "self-start", paddingTop: "8px", color: "#6e6e6e"}}>{ind + 1}</span>
                <img data-id={slideId} src={mirrorSlides[slideId]} alt="mirror" className="sider-slide" width='100%'  height='100%' style={{filter:!darkMode?!slideBackgroundColor|| slideBackgroundColor=='#fff'?'invert(85%)':'none':'none'}}/>
            </div>
    )
}

export default React.memo(SidebarSlideView);