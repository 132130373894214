import React, { useState } from 'react';
import layoutBlank from '../../assets/Layouts/blank.png';
import layoutTitleBody from '../../assets/Layouts/layout01.png';
import layoutTitleTwoColumns from '../../assets/Layouts/layout02.png';
import layoutSectionHeader from '../../assets/Layouts/layout03.png';
import layoutTitleOneColumn from '../../assets/Layouts/layout04.png';
import layoutTitleOnly from '../../assets/Layouts/layout05.png';
import LayoutEnum from '../../utils/layoutEnum';
import { modifyMasterSlideData } from '../../utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import layoutsArray from '../../utils/layoutStructure';
import { slideAction } from '../../store/slideSlice';
import { v4 } from "uuid";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { slideAPI } from '../../store/api';

const MasterSlideSideBar = ({ selectedSlideLayout, setSelectedSlideLayout }) => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const existingSlides = useSelector((state) => state.slide.slides);

    const handleSlideClick = (e,slideId) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedSlideLayout(slideId);
        const existingLayoutId = Object.keys(existingSlides).find(id => id.toLowerCase().includes(slideId.toLowerCase()));
        if (existingLayoutId) {
            dispatch(slideAction.setMasterSlideId(existingLayoutId));
            dispatch(slideAction.setCurrentSlide(existingLayoutId));
        }
        else {
            let newSlideId = `master-${slideId + '-' + v4()}`;
            let slideData = layoutsArray.filter((layout) => layout.key === slideId)?.[0]?.structure;
            let masterSlideId = Object.keys(existingSlides).find(id => id.toLowerCase().includes('master')
                && !id.toLowerCase().includes(slideId.toLowerCase()));
            let masterSlideData = modifyMasterSlideData(existingSlides[masterSlideId] || null);
                        if (masterSlideData) {
                slideData = {
                    slideBackgroundColor: masterSlideData.slideBackgroundColor,
                    textBoxIds: [...slideData.textBoxIds, ...masterSlideData.textBoxIds],
                    textBox: { ...slideData.textBox, ...masterSlideData.textBox },
                    imageIds: [...masterSlideData.imageIds],
                    image: { ...slideData.image, ...masterSlideData.image },
                    shapes: { ...slideData.shapes, ...masterSlideData.shapes },
                    speakerNotes: slideData.speakerNotes,
                }
            }
            const newSlideData = {
                slideId: newSlideId,
                slideData,
            };
            dispatch(slideAction.setMasterSlideId(newSlideId));
            dispatch(slideAction.setCurrentSlide(newSlideId));
            dispatch(slideAction.addSlide(newSlideData));
            slideAPI.addNewSlideInDatabase(id, newSlideId, slideData);
        }
    };

    const renderImage = (image, id) => (
        <img
            src={image}
            width={'180px'}
            style={{
                border: `1px solid ${selectedSlideLayout === id ? '#6eb7fa' : 'silver'}`,
                borderWidth: selectedSlideLayout === id ? '2px' : '1px',
                cursor: 'pointer',
                marginBottom: '20px',
            }}
            onClick={(e) => handleSlideClick(e,id)}
            alt={`Layout ${id}`}
        />
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
                <span>THEME</span>
                {renderImage(layoutBlank, 'master')}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexWrap: 'wrap' }}>
                <span>LAYOUTS</span>
                {renderImage(layoutTitleBody, LayoutEnum.TITLE_BODY)}
                {renderImage(layoutTitleTwoColumns, LayoutEnum.TITLE_TWO_COLUMNS)}
                {renderImage(layoutSectionHeader, LayoutEnum.SECTION_HEADER)}
                {renderImage(layoutTitleOneColumn, LayoutEnum.TITLE_ONE_COLUMN)}
                {renderImage(layoutTitleOnly, LayoutEnum.TITLE_ONLY)}
                {renderImage(layoutBlank, LayoutEnum.BLANK)}
            </div>
        </div>
    );
};

export default MasterSlideSideBar;