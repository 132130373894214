import { createSlice } from "@reduxjs/toolkit";


const initialState = {};

const quillRegistrySlice = createSlice({
    name: "quillRegistry",
    initialState,
    reducers: {
    setInstance(state,actions){
        const { quillContainerId, quillInstance} = actions.payload;
        state[quillContainerId] = quillInstance;
        
    },
    },
  });
  
  export const quillAction = quillRegistrySlice.actions;

  export default quillRegistrySlice.reducer;