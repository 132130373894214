import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useContextMenu from "../hooks/use-contextMenu";
import { emitElementAdd, emitElementDelete, emitSlideDelete, emitStyleUpdate, emitSlideAdd } from "../Socket/Socket";
import { slideAction } from "../store/slideSlice";
import { getSnapshot } from "../utils/utils";
import { useParams } from "react-router-dom";
import { slideAPI } from "../store/api";
import { v4 } from "uuid";
import AddIcon from '@mui/icons-material/Add';
import Slides from "../components/Slide/Slides";
import useResizer from "../hooks/use-resizer";
import LayoutEnum from "../utils/layoutEnum";
import { deleteElement, getUpdatedSlides, updateElementStyle } from "../utils/helper";

const ContextMenu = () => {
  const { showMenu, elemRef } = useContextMenu();
  const { xPos, yPos, show, disable } = showMenu;
  const { id } = useParams();
  const [copiedData, setCopiedData] = useState(null);
  return (
    <>
      {show && (
        <div
          className="menu-container"
          style={{
            top: yPos,
            left: xPos,
          }}
        >
          <Menu elementRef={elemRef} disable={disable} presentationId={id}
            copiedData={copiedData} setCopiedData={setCopiedData} />
        </div>
      )}
    </>
  );
};

export default ContextMenu;

export const Menu = ({ elementRef, disable, presentationId, copiedData, setCopiedData }) => {
  const {
    deleteElementFromDatabase,
    deleteSlideFromDatabase, addNewSlideInDatabase, createNewElementInDatabase, updateShapeTextInDatabase } = slideAPI;
  const [showOrderSubMenu, setShowOrderSubMenu] = useState(false);
  const documentInformation = useSelector((state) => state.slide.documentInformation);
  const [addResizer, removeResizer, dragEvent] = useResizer();

  let currentSlideId = useSelector(state => state.slide.currentSlideId);
  const isMasterSlide = useSelector((state) => state.slide.isMasterSlide);
  const masterSlideId = useSelector(state => state.slide.masterSlideId);
  const existingSlides = useSelector((state) => state.slide.slides);
  const mirrorSlides = useSelector((state) => state.slide.mirrorSlides);
  if (isMasterSlide && masterSlideId) {
    currentSlideId = masterSlideId;
  }
  else if (isMasterSlide && !masterSlideId) {
    const existingMasterSlideId = Object.keys(existingSlides).find(slideId => slideId.toLowerCase().includes("master"));
    if (existingMasterSlideId) {
      currentSlideId = existingMasterSlideId;
    }
  }
  const slides = useSelector(state => state.slide.slides);
  const dispatch = useDispatch();

  const deleteFromMasterLayout = (type, elementId) => {
    const isMainMasterSlide = !Object.values(LayoutEnum).some(layout => currentSlideId.toLowerCase().includes(layout.toLowerCase()));
    if (!isMainMasterSlide) {
      return;
    }
    Object.keys(existingSlides).forEach((slideId) => {
      const isMasterLayout = Object.values(LayoutEnum).some((enumValue) =>
        slideId.includes(enumValue)
      );
      if (isMasterLayout) {
        switch (type) {
          case 'textbox':
            dispatch(slideAction.removeTextBox({ slideId: slideId, textBoxId: elementId }));
            break;
          case 'image':
            dispatch(slideAction.removeImage({ slideId: slideId, imageId: elementId }));
            break;
          case 'shape':
            dispatch(slideAction.removeShape({ slideId: slideId, shapeId: elementId }));
            break;
        }
      }
    });
  };

  const removeElementHandler = () => {
    let payload = {};
    let updatedSlides = {};
    let data = {};
    if (elementRef.current.id && (elementRef.current.id.includes("title") || elementRef.current.id.includes("layout") || elementRef.current.id.includes("text"))) {
      emitElementDelete({ roomId: presentationId, elementType: "text", elementData: { slideId: currentSlideId, textBoxId: elementRef.current.id } })

      if (isMasterSlide && currentSlideId.toLowerCase().includes('master')) {
        dispatch(slideAction.addDeletedElementId({
          elementId: elementRef.current.id,
          elementData: existingSlides[currentSlideId].textBox[elementRef.current.id]
        }));
        deleteFromMasterLayout('textbox', elementRef.current.id);
      }

      dispatch(slideAction.removeTextBox({ slideId: currentSlideId, textBoxId: elementRef.current.id }));
      if (!isMasterSlide) {
        payload = {
          elementId: elementRef.current.id,
          slideId: currentSlideId
        }
        updatedSlides = deleteElement(existingSlides, payload, 'textbox');
        data = {
          slides: updatedSlides,
          mirrorSlides: mirrorSlides
        }
        deleteElementFromDatabase(presentationId, currentSlideId, elementRef.current.id, 'text', dispatch, data);
        getSnapshot().then((dataUrl) => {
          dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
        });
      }
    } else if (elementRef.current.id && elementRef.current.parentElement.id && (elementRef.current.parentElement.id.includes("title") ||
      elementRef.current.parentElement.id.includes("Title") || elementRef.current.parentElement.id.includes("layout")
      || elementRef.current.parentElement.id.includes("text"))) {

      if (isMasterSlide && currentSlideId.toLowerCase().includes('master')) {
        dispatch(slideAction.addDeletedElementId({
          elementId: elementRef.current.parentElement.id,
          elementData: existingSlides[currentSlideId].textBox[elementRef.current.parentElement.id]
        }));
        deleteFromMasterLayout('textbox', elementRef.current.parentElement.id);
      }
      emitElementDelete({ roomId: presentationId, elementType: "text", elementData: { slideId: currentSlideId, textBoxId: elementRef.current.parentElement.id } })
      dispatch(slideAction.removeTextBox({ slideId: currentSlideId, textBoxId: elementRef.current.parentElement.id }));
      if (!isMasterSlide) {
        payload = {
          elementId: elementRef.current.parentElement.id,
          slideId: currentSlideId
        }
        updatedSlides = deleteElement(existingSlides, payload, 'textbox');
        data = {
          slides: updatedSlides,
          mirrorSlides: mirrorSlides
        }
        deleteElementFromDatabase(presentationId, currentSlideId, elementRef.current.parentElement.id, 'text', dispatch, data);
        getSnapshot().then((dataUrl) => {
          dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
        });
      }
    }
    else if (elementRef.current.id.includes('shape')) {

      if (isMasterSlide && currentSlideId.toLowerCase().includes('master')) {
        dispatch(slideAction.addDeletedElementId({
          elementId: elementRef.current.id,
          elementData: existingSlides[currentSlideId].shapes[elementRef.current.id]
        }));
        deleteFromMasterLayout('shape', elementRef.current.id);
      }

      emitElementDelete({ roomId: presentationId, elementType: "shape", elementData: { slideId: currentSlideId, shapeId: elementRef.current.parentElement.id } })
      dispatch(slideAction.removeShape({ slideId: currentSlideId, shapeId: elementRef.current.parentElement.id }));
      if (!isMasterSlide) {
        payload = {
          elementId: elementRef.current.id,
          slideId: currentSlideId
        }
        updatedSlides = deleteElement(existingSlides, payload, 'shape');
        data = {
          slides: updatedSlides,
          mirrorSlides: mirrorSlides
        }
        deleteElementFromDatabase(presentationId, currentSlideId, elementRef.current.parentElement.id, 'shape', dispatch, data);
        getSnapshot().then((dataUrl) => {
          dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
        });
      }
    }
    else if (elementRef.current.tagName === "IMG") {
      if (elementRef.current.getAttribute('data-id')) {
        emitSlideDelete({ roomId: presentationId, slideId: elementRef.current.getAttribute('data-id') });
        dispatch(slideAction.removeSlide(elementRef.current.getAttribute('data-id')));
        updatedSlides = deleteElement(existingSlides, { slideId: elementRef.current.getAttribute('data-id') }, 'slide');
        data = {
          slides: updatedSlides,
          mirrorSlides: mirrorSlides
        }
        deleteSlideFromDatabase(presentationId, elementRef.current.getAttribute('data-id'), currentSlideId, dispatch, data);
      } else if (elementRef.current.id && elementRef.current.id.includes("image")) {

        if (isMasterSlide && currentSlideId.toLowerCase().includes('master')) {
          dispatch(slideAction.addDeletedElementId({
            elementId: elementRef.current.id,
            elementData: existingSlides[currentSlideId].image[elementRef.current.id]
          }));
          deleteFromMasterLayout('image', elementRef.current.id);
        }

        emitElementDelete({ roomId: presentationId, elementType: "image", elementData: { slideId: currentSlideId, imageId: elementRef.current.id } })
        dispatch(slideAction.removeImage({ slideId: currentSlideId, imageId: elementRef.current.id }));
        if (!isMasterSlide) {
          payload = {
            elementId: elementRef.current.id,
            slideId: currentSlideId
          }
          updatedSlides = deleteElement(existingSlides, payload, 'image');
          data = {
            slides: updatedSlides,
            mirrorSlides: mirrorSlides
          }
          deleteElementFromDatabase(presentationId, currentSlideId, elementRef.current.id, 'image', dispatch, data);
          getSnapshot().then((dataUrl) => {
            dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
          });
        }
      } else if (elementRef.current.parentElement.id && elementRef.current.parentElement.id.includes('image')) {

        if (isMasterSlide && currentSlideId.toLowerCase().includes('master')) {
          dispatch(slideAction.addDeletedElementId({
            elementId: elementRef.current.parentElement.id,
            elementData: existingSlides[currentSlideId].image[elementRef.current.parentElement.id]
          }));
          deleteFromMasterLayout('image', elementRef.current.parentElement.id);
        }

        emitElementDelete({ roomId: presentationId, elementType: "image", elementData: { slideId: currentSlideId, imageId: elementRef.current.parentElement.id } })
        dispatch(slideAction.removeImage({ slideId: currentSlideId, imageId: elementRef.current.parentElement.id }));
        if (!isMasterSlide) {
          payload = {
            elementId: elementRef.current.parentElement.id,
            slideId: currentSlideId
          }
          updatedSlides = deleteElement(existingSlides, payload, 'image');
          data = {
            slides: updatedSlides,
            mirrorSlides: mirrorSlides
          }
          deleteElementFromDatabase(presentationId, currentSlideId, elementRef.current.parentElement.id, 'image', dispatch, data);
          getSnapshot().then((dataUrl) => {
            dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
          });
        }
      }
      else if (elementRef.current.id.includes('shape')) {

        if (isMasterSlide && currentSlideId.toLowerCase().includes('master')) {
          dispatch(slideAction.addDeletedElementId({
            elementId: elementRef.current.id,
            elementData: existingSlides[currentSlideId].shapes[elementRef.current.id]
          }));
          deleteFromMasterLayout('shape', elementRef.current.id);
        }

        emitElementDelete({ roomId: presentationId, elementType: "shape", elementData: { slideId: currentSlideId, shapeId: elementRef.current.parentElement.id } })
        dispatch(slideAction.removeShape({ slideId: currentSlideId, shapeId: elementRef.current.parentElement.id }));
        if (!isMasterSlide) {
          payload = {
            elementId: elementRef.current.parentElement.id,
            slideId: currentSlideId
          }
          updatedSlides = deleteElement(existingSlides, payload, 'shape');
          data = {
            slides: updatedSlides,
            mirrorSlides: mirrorSlides
          }
          deleteElementFromDatabase(presentationId, currentSlideId, elementRef.current.parentElement.id, 'shape', dispatch, data);
          getSnapshot().then((dataUrl) => {
            dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
          });
        }
      }
    }

  }
  const orderMenuMouseEnterHandler = () => {
    setShowOrderSubMenu(true);
  };

  const orderMenuMouseLeaveHandler = () => {
    setShowOrderSubMenu(false);
  };
  const moveToFrontHandler = () => {
    if (elementRef.current) {
      const slideContainer = document.querySelector(".slide-container");
      let highestZIndex = 0;
      for (const elem of slideContainer.children) {
        const zIndex = parseInt(window.getComputedStyle(elem).zIndex);
        if (!isNaN(zIndex) && zIndex > highestZIndex) {
          highestZIndex = zIndex;
        }
      }
      if (elementRef.current.id && (elementRef.current.id.includes("title") || elementRef.current.id.includes("Title")
        || elementRef.current.id.includes("text") || elementRef.current.id.includes("layout"))) {
        let updatedStyle;
        elementRef.current.style.zIndex = highestZIndex + 1;
        updatedStyle = {
          height: elementRef.current.style.height,
          width: elementRef.current.style.width,
          position: elementRef.current.style.position,
          left: elementRef.current.style.left,
          top: elementRef.current.style.top,
          zIndex: highestZIndex + 1
        }
        emitStyleUpdate({
          roomId: presentationId,
          elementData:
          {
            slideId: currentSlideId,
            textBoxId: elementRef.current.id,
            updatedStyle: updatedStyle
          }
        });
        dispatch(slideAction.updateStyle({
          slideId: currentSlideId,
          textBoxId: elementRef.current.id,
          updatedStyle: updatedStyle
        }
        ));
        getSnapshot().then((dataUrl) => {
          dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
        });
        let payload = {
          textBoxId: elementRef.current.id,
          updatedStyle: updatedStyle,
          currentSlideId: currentSlideId
        }
        const updatedSlides = updateElementStyle(existingSlides, payload, 'text');
        const data = {
          slides: updatedSlides,
          mirrorSlides: mirrorSlides
        }
        !currentSlideId.includes('master') && slideAPI.updateElementStyleInDatabase(
          presentationId,
          currentSlideId,
          elementRef.current.id,
          updatedStyle,
          'text',
          dispatch,
          data
        );
      }
      else if (elementRef.current.id && elementRef.current.id.includes("image")) {
        elementRef.current.style.zIndex = highestZIndex + 1;
        getSnapshot().then((dataUrl) => {
          dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
        });
      }
      else if (elementRef.current.parentElement && elementRef.current.parentElement.id &&
        elementRef.current.parentElement.id.includes('image')) {
        let updatedStyle;
        const parentDiv = elementRef.current.parentElement;
        parentDiv.style.zIndex = highestZIndex + 1;
        updatedStyle = {
          height: parentDiv.style.height,
          width: parentDiv.style.width,
          position: parentDiv.style.position,
          left: parentDiv.style.left,
          top: parentDiv.style.top,
          zIndex: highestZIndex + 1
        }
        emitStyleUpdate({
          roomId: presentationId,
          elementData:
          {
            slideId: currentSlideId,
            textBoxId: parentDiv.id,
            updatedStyle: updatedStyle
          }
        });
        dispatch(slideAction.updateStyle({
          slideId: currentSlideId,
          textBoxId: parentDiv.id,
          updatedStyle: updatedStyle
        }
        ));
        getSnapshot().then((dataUrl) => {
          dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
        });
        let payload = {
          textBoxId: parentDiv.id,
          updatedStyle: updatedStyle,
          currentSlideId: currentSlideId
        }
        const updatedSlides = updateElementStyle(existingSlides, payload, 'image');
        const data = {
          slides: updatedSlides,
          mirrorSlides: mirrorSlides
        }
        !currentSlideId.includes('master') && slideAPI.updateElementStyleInDatabase(
          presentationId,
          currentSlideId,
          parentDiv.id,
          updatedStyle,
          'image',
          dispatch,
          data
        );
      }
      else if (elementRef.current.id.includes('shape')) {
        let updatedStyle;
        let parentDiv;
        if (elementRef.current.parentElement?.parentElement?.parentElement?.id.includes('shape')) {
          parentDiv = elementRef.current.parentElement.parentElement.parentElement;
        } else if (elementRef.current.parentElement?.parentElement?.id.includes('shape')) {
          parentDiv = elementRef.current.parentElement.parentElement;
        } else if (elementRef.current.parentElement.id.includes('shape')) {
          parentDiv = elementRef.current.parentElement;
        } else {
          parentDiv = elementRef.current;
        }
        parentDiv.style.zIndex = highestZIndex + 1;
        updatedStyle = {
          height: parentDiv.style.height,
          width: parentDiv.style.width,
          position: parentDiv.style.position,
          left: parentDiv.style.left,
          top: parentDiv.style.top,
          zIndex: highestZIndex + 1
        }
        emitStyleUpdate({
          roomId: presentationId,
          elementData:
          {
            slideId: currentSlideId,
            textBoxId: parentDiv.id,
            updatedStyle: updatedStyle
          }
        });
        dispatch(slideAction.updateStyle({
          slideId: currentSlideId,
          textBoxId: parentDiv.id,
          updatedStyle: updatedStyle
        }));
        getSnapshot().then((dataUrl) => {
          dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
        });
        let payload = {
          textBoxId: parentDiv.id,
          updatedStyle: updatedStyle,
          currentSlideId: currentSlideId
        }
        const updatedSlides = updateElementStyle(existingSlides, payload, 'shape');
        const data = {
          slides: updatedSlides,
          mirrorSlides: mirrorSlides
        }
        !currentSlideId.includes('master') && slideAPI.updateElementStyleInDatabase(
          presentationId,
          currentSlideId,
          parentDiv.id,
          updatedStyle,
          'shape',
          dispatch,
          data
        );
      }
    }
  };
  const moveToBackHandler = () => {
    if (elementRef.current) {
      const slideContainer = document.querySelector(".slide-container");
      let lowestZIndex = Infinity;
      for (const elem of slideContainer.children) {
        const zIndex = parseInt(window.getComputedStyle(elem).zIndex);
        if (!isNaN(zIndex) && zIndex < lowestZIndex) {
          lowestZIndex = zIndex;
        }
      }
      if (elementRef.current.id && (elementRef.current.id.includes("Title") || elementRef.current.id.includes("title")
        || elementRef.current.id.includes("text") || elementRef.current.id.includes("layout"))) {
        let updatedStyle;
        if (lowestZIndex == 0) {
          elementRef.current.style.zIndex = lowestZIndex;
        }
        else {
          elementRef.current.style.zIndex = lowestZIndex - 1;
          lowestZIndex = lowestZIndex - 1;
        }
        updatedStyle = {
          height: elementRef.current.style.height,
          width: elementRef.current.style.width,
          position: elementRef.current.style.position,
          left: elementRef.current.style.left,
          top: elementRef.current.style.top,
          zIndex: lowestZIndex
        }
        emitStyleUpdate({
          roomId: presentationId,
          elementData:
          {
            slideId: currentSlideId,
            textBoxId: elementRef.current.id,
            updatedStyle: updatedStyle
          }
        });
        dispatch(slideAction.updateStyle({
          slideId: currentSlideId,
          textBoxId: elementRef.current.id,
          updatedStyle: updatedStyle
        }
        ));
        getSnapshot().then((dataUrl) => {
          dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
        });
        let payload = {
          textBoxId: elementRef.current.id,
          updatedStyle: updatedStyle,
          currentSlideId: currentSlideId
        }
        const updatedSlides = updateElementStyle(existingSlides, payload, 'text');
        const data = {
          slides: updatedSlides,
          mirrorSlides: mirrorSlides
        }
        !currentSlideId.includes('master') && slideAPI.updateElementStyleInDatabase(
          presentationId,
          currentSlideId,
          elementRef.current.id,
          updatedStyle,
          'text',
          dispatch,
          data
        );
      }
      else if (elementRef.current.parentElement && elementRef.current.parentElement.id &&
        elementRef.current.parentElement.id.includes('image')) {
        const parentDiv = elementRef.current.parentElement;
        let updatedStyle;
        if (lowestZIndex == 0) {
          parentDiv.style.zIndex = lowestZIndex;
        }
        else {
          parentDiv.style.zIndex = lowestZIndex - 1;
          lowestZIndex = lowestZIndex - 1;
        }
        updatedStyle = {
          height: parentDiv.style.height,
          width: parentDiv.style.width,
          position: parentDiv.style.position,
          left: parentDiv.style.left,
          top: parentDiv.style.top,
          zIndex: lowestZIndex
        }
        emitStyleUpdate({
          roomId: presentationId,
          elementData:
          {
            slideId: currentSlideId,
            textBoxId: parentDiv.id,
            updatedStyle: updatedStyle
          }
        });
        dispatch(slideAction.updateStyle({
          slideId: currentSlideId,
          textBoxId: parentDiv.id,
          updatedStyle: updatedStyle
        }
        ));
        getSnapshot().then((dataUrl) => {
          dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
        });
        let payload = {
          textBoxId: parentDiv.id,
          updatedStyle: updatedStyle,
          currentSlideId: currentSlideId
        }
        const updatedSlides = updateElementStyle(existingSlides, payload, 'image');
        const data = {
          slides: updatedSlides,
          mirrorSlides: mirrorSlides
        }
        !currentSlideId.includes('master') && slideAPI.updateElementStyleInDatabase(
          presentationId,
          currentSlideId,
          parentDiv.id,
          updatedStyle,
          'image',
          dispatch,
          data
        );
      }
      else if (elementRef.current.id.includes('shape')) {
        let parentDiv, updatedStyle;
        if (elementRef.current.parentElement?.parentElement?.parentElement?.id.includes('shape')) {
          parentDiv = elementRef.current.parentElement.parentElement.parentElement;
        } else if (elementRef.current.parentElement?.parentElement?.id.includes('shape')) {
          parentDiv = elementRef.current.parentElement.parentElement;
        } else if (elementRef.current.parentElement.id.includes('shape')) {
          parentDiv = elementRef.current.parentElement;
        } else {
          parentDiv = elementRef.current;
        }
        if (lowestZIndex === 0) {
          parentDiv.style.zIndex = lowestZIndex;

        } else {
          parentDiv.style.zIndex = lowestZIndex - 1;
          lowestZIndex = lowestZIndex - 1;
        }
        updatedStyle = {
          height: parentDiv.style.height,
          width: parentDiv.style.width,
          position: parentDiv.style.position,
          left: parentDiv.style.left,
          top: parentDiv.style.top,
          zIndex: lowestZIndex
        }
        emitStyleUpdate({
          roomId: presentationId,
          elementData:
          {
            slideId: currentSlideId,
            textBoxId: parentDiv.id,
            updatedStyle: updatedStyle
          }
        });
        dispatch(slideAction.updateStyle({
          slideId: currentSlideId,
          textBoxId: parentDiv.id,
          updatedStyle: updatedStyle
        }
        ));
        getSnapshot().then((dataUrl) => {
          dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
        });
        let payload = {
          textBoxId: parentDiv.id,
          updatedStyle: updatedStyle,
          currentSlideId: currentSlideId
        }
        const updatedSlides = updateElementStyle(existingSlides, payload, 'shape');
        const data = {
          slides: updatedSlides,
          mirrorSlides: mirrorSlides
        }
        !currentSlideId.includes('master') && slideAPI.updateElementStyleInDatabase(
          presentationId,
          currentSlideId,
          parentDiv.id,
          updatedStyle,
          'shape',
          dispatch,
          data
        );
      }
      else if (elementRef.current.tagName === "IMG") {
        if (elementRef.current.getAttribute('data-id')) {

        }
      }
    }
  };
  const addSlideHandler = () => {
    const slideId = "slide" + v4();
    const slideData = {
      slideBackgroundColor: "#fff",
      textBoxIds: [],
      textBox: {},
      imageIds: [],
      image: {},
      shapes: {},
      speakerNotes: "",
      additionalData: {
        isSlideColorChangedBy: false,
      }
    };
    const newSlideData = {
      slideId,
      slideData,
    };
    let selectedIndex;
    Object.keys(slides).forEach((key, index) => {
      if (key == currentSlideId) {
        selectedIndex = index;
      }
    });
    const slideArray = Object.keys(slides).map(key => ({ id: key, data: slides[key] }));
    slideArray.splice(selectedIndex + 1, 0, { id: slideId, data: newSlideData.slideData });

    const newSlidesObj = {};       // slides object with new slide added after selected slide
    slideArray.forEach(slide => {
      newSlidesObj[slide.id] = slide.data;
    });
    // dispatch(slideAction.setSlides(newSlidesObj)); it will use to set the entire slides order
    dispatch(slideAction.addSlide(newSlideData));
    dispatch(slideAction.setCurrentSlide(slideId));
    setTimeout(
      () =>
        getSnapshot().then((dataUrl) => {
          dispatch(
            slideAction.saveMirror({
              dataId: slideId,
              mirrorSlideDataUrl: dataUrl,
            })
          );
          emitSlideAdd({ roomId: presentationId, mirrorData: { dataId: slideId, mirrorSlideDataUrl: dataUrl }, slideData: { slideId, slideData } });
          const updatedSlides = getUpdatedSlides(newSlideData, 'slide', existingSlides);
          const data = {
            slides: updatedSlides,
            mirrorSlides: mirrorSlides
          }
          addNewSlideInDatabase(presentationId, slideId, slideData, dispatch, data);
        }),
      0
    );
  };
  const moveSlideToBeginning = () => {
    dispatch(slideAction.moveSlideToBeginning(currentSlideId));
  };

  const moveSlideToEnd = () => {
    dispatch(slideAction.moveSlideToEnd(currentSlideId));
  };
  const duplicateSlideHandler = () => {
    if (elementRef.current.id.includes('slide01') || elementRef.current.tagName === "IMG" && (
      (elementRef.current.getAttribute('data-id')))) {
      const slideId = "slide-dublicate-" + v4();
      const currentSlide = slides[currentSlideId];
      const slideData = currentSlide;
      const newSlideData = {
        slideId,
        slideData,
      };
      dispatch(slideAction.addSlide(newSlideData));
      dispatch(slideAction.setCurrentSlide(slideId));
      setTimeout(
        () =>
          getSnapshot().then((dataUrl) => {
            dispatch(
              slideAction.saveMirror({
                dataId: slideId,
                mirrorSlideDataUrl: dataUrl,
              })
            );
            emitSlideAdd({ roomId: presentationId, mirrorData: { dataId: slideId, mirrorSlideDataUrl: dataUrl }, slideData: { slideId, slideData } });
            const updatedSlides = getUpdatedSlides(newSlideData, 'slide', existingSlides);
            const data = {
              slides: updatedSlides,
              mirrorSlides: mirrorSlides
            }
            addNewSlideInDatabase(presentationId, slideId, slideData, dispatch, data);
          }),
        0
      );
    }
    else if (elementRef.current.id && (elementRef.current.id.includes("title") ||
      elementRef.current.id.includes("layout") || elementRef.current.id.includes("text"))) {
      const currentTextBox = slides[currentSlideId].textBox[elementRef.current.id];
      const textId = 'text-duplicate-' + v4();
      const textStyle = {
        ...currentTextBox?.styles,
        top: `${parseFloat(currentTextBox?.styles.top) + 2}%`,
        left: `${parseFloat(currentTextBox?.styles.left) + 2}%`
      };
      const data = {
        textId,
        currentSlideId,
        style: textStyle,
        delta: currentTextBox?.delta
      }
      dispatch(slideAction.addTextBox(data));
      emitElementAdd({ roomId: presentationId, elementType: "text", elementData: data });
      const updatedSlides = getUpdatedSlides(data, 'text', existingSlides);
      const lastSaveData = {
        slides: updatedSlides,
        mirrorSlides: mirrorSlides
      }
      createNewElementInDatabase(presentationId, currentSlideId, textId, {
        delta: currentTextBox.delta,
        styles: textStyle,
      }, 'text', dispatch, lastSaveData);

    }
    else if (elementRef.current.id.includes('shape')) {
      const shapeType = elementRef.current.id.slice(0, 8);
      const shapeId = shapeType + '-duplicate-' + 'shape' + v4();
      const currentShape = slides[currentSlideId].shapes[elementRef.current.id];
      const shapeStyle = {
        ...currentShape.styles,
        top: `${parseFloat(currentShape?.styles.top) + 2}%`,
        left: `${parseFloat(currentShape?.styles.left) + 2}%`,
      }
      const shapeData = {
        shapeId,
        currentSlideId,
        style: shapeStyle,
        delta: currentShape?.delta
      };
      dispatch(slideAction.addShape(shapeData));
      emitElementAdd({ roomId: presentationId, elementType: "shape", elementData: shapeData });
      const updatedSlides = getUpdatedSlides(shapeData, 'shape', existingSlides);
      let lastSaveData = {
        slides: updatedSlides,
        mirrorSlides: mirrorSlides
      }
      createNewElementInDatabase(presentationId, currentSlideId, shapeId, {
        delta: currentShape.delta,
        styles: shapeStyle,
      }, 'shape', dispatch, lastSaveData);
    }
    else if (elementRef?.current?.parentElement?.id && elementRef.current.parentElement.id.includes('image')) {
      const imageId = "image" + '-duplicate-' + v4();
      const currentImage = slides[currentSlideId].image[elementRef.current.parentElement.id];
      const imageb64 = currentImage.imageb64;
      const style = {
        ...currentImage?.styles,
        top: `${parseFloat(currentImage?.styles.top) + 2}%`,
        left: `${parseFloat(currentImage?.styles.left) + 2}%`
      };
      const imageData = {
        imageId,
        imageb64: currentImage.imageb64,
        style: style,
        currentSlideId,
      };
      dispatch(slideAction.addImage(imageData));
      emitElementAdd({ roomId: presentationId, elementType: "image", elementData: { imageId, imageb64, style, currentSlideId } });
      const updatedSlides = getUpdatedSlides(imageData, 'image', existingSlides);
      let lastSaveData = {
        slides: updatedSlides,
        mirrorSlides: mirrorSlides
      }
      createNewElementInDatabase(presentationId, currentSlideId, imageId, { imageb64: imageb64, styles: style }, 'image', dispatch, lastSaveData);
    }
  }
  const copyHandler = () => {
    const getId = (prefix) => `${prefix}-copy-${v4()}`;
    const getNewStyle = (currentStyles) => ({
      ...currentStyles,
      top: `${parseFloat(currentStyles.top) + 2}%`,
      left: `${parseFloat(currentStyles.left) + 2}%`
    });

    if (elementRef.current) {
      const id = elementRef.current.id;

      let copiedDataToUpdate = {};

      if (id.includes('slide01') || (elementRef.current.tagName === "IMG" && elementRef.current.getAttribute('data-id'))) {
        const slideId = getId("slide");
        copiedDataToUpdate = { id: slideId, data: slides[currentSlideId], type: 'slide' };
      } else if (id.includes("title") || id.includes("layout") || id.includes("text")) {
        const textId = getId('text');
        const currentTextBox = slides[currentSlideId]?.textBox[id];
        const textStyle = getNewStyle(currentTextBox?.styles);
        copiedDataToUpdate = {
          textId,
          delta: currentTextBox?.delta,
          type: 'textbox',
          originalStyle: textStyle,
          slideStyles: { [currentSlideId]: textStyle },
        };
      } else if (id.includes('shape')) {
        const shapeType = id.slice(0, 8);
        const shapeId = getId(shapeType + '-shape');
        const currentShape = slides[currentSlideId]?.shapes[id];
        const shapeStyle = getNewStyle(currentShape?.styles);
        copiedDataToUpdate = {
          shapeId,
          delta: currentShape?.delta,
          type: 'shape',
          originalStyle: shapeStyle,
          slideStyles: { [currentSlideId]: shapeStyle },
        };
      } else if (elementRef.current.parentElement?.id && elementRef.current.parentElement.id.includes('image')) {
        const imageId = getId('image');
        const currentImage = slides[currentSlideId]?.image[elementRef.current.parentElement.id];
        const imgStyle = getNewStyle(currentImage?.styles);
        copiedDataToUpdate = {
          imageId,
          imageb64: currentImage.imageb64,
          type: "image",
          originalStyle: imgStyle,
          slideStyles: { [currentSlideId]: imgStyle },
        };
      }
      setCopiedData(copiedDataToUpdate);
    }
  };

  const pasteHandler = () => {

    if (copiedData) {

      const { type, id, data, imageb64, delta, originalStyle, slideStyles } = copiedData;
      let updatedStyle;
      let elementData;
      let lastSaveData;
      let updatedSlides;
      if (type !== 'slide') {
        const currentSlideStyle = slideStyles?.[currentSlideId] || null;

        if (currentSlideStyle) {
          updatedStyle = {
            ...currentSlideStyle,
            top: `${parseFloat(currentSlideStyle.top) + 2}%`,
            left: `${parseFloat(currentSlideStyle.left) + 2}%`,
          };
        }
        else {
          updatedStyle = originalStyle;
        }
        let updatedSlideStyles = { ...slideStyles };
        updatedSlideStyles[currentSlideId] = updatedStyle;
        setCopiedData({
          ...copiedData,
          style: updatedStyle,
          slideStyles: updatedSlideStyles,
        });
      }
      const dispatchAddAction = (action, elementId) => {
        addResizerWithTimeout(elementId);
        getSnapshot().then((dataUrl) => {
          dispatch(action);
          dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
        });
        // setCopiedData(null);
      };
      switch (type) {
        case 'slide':
          let slideId = `copy-slide-${v4()}`;
          let slideData = data;
          const newSlideData = {
            slideId,
            slideData: data,
          };
          dispatch(slideAction.addSlide(newSlideData));
          dispatch(slideAction.setCurrentSlide(newSlideData.slideId));
          setTimeout(
            () =>
              getSnapshot().then((dataUrl) => {
                dispatch(
                  slideAction.saveMirror({
                    dataId: slideId,
                    mirrorSlideDataUrl: dataUrl,
                  })
                );
                emitSlideAdd({ roomId: presentationId, mirrorData: { dataId: slideId, mirrorSlideDataUrl: dataUrl }, slideData: { slideId, slideData } });
                const updatedSlides = getUpdatedSlides(newSlideData, 'slide', existingSlides);
                const data = {
                  slides: updatedSlides,
                  mirrorSlides: mirrorSlides
                }
                addNewSlideInDatabase(presentationId, slideId, slideData, dispatch, data);
              }),
            0
          );
          break;
        case 'textbox':
          let textId = `copy-text-${v4()}`;
          elementData = {
            textId: textId,
            style: updatedStyle,
            delta: delta,
            currentSlideId: currentSlideId
          }
          updatedSlides = getUpdatedSlides(elementData, 'text', existingSlides);
          lastSaveData = {
            slides: updatedSlides,
            mirrorSlides: mirrorSlides
          }
          dispatchAddAction(slideAction.addTextBox({ textId: textId, currentSlideId, style: updatedStyle, delta }), textId, 'text');
          createNewElementInDatabase(presentationId, currentSlideId, textId, { delta, styles: updatedStyle }, 'text', dispatch, lastSaveData);
          emitElementAdd({ roomId: presentationId, elementType: "text", elementData: { textId, delta, style: updatedStyle, currentSlideId } });
          break;
        case 'shape':
          let shapeId = `${copiedData.shapeId.slice(0, 8)}-copy-shape-${v4()}`;
          elementData = {
            shapeId: shapeId,
            style: updatedStyle,
            delta: delta,
            currentSlideId: currentSlideId
          }
          updatedSlides = getUpdatedSlides(elementData, 'shape', existingSlides);
          lastSaveData = {
            slides: updatedSlides,
            mirrorSlides: mirrorSlides
          }
          dispatchAddAction(slideAction.addShape({ shapeId: shapeId, currentSlideId: currentSlideId, style: updatedStyle, delta }), shapeId, 'shape');
          createNewElementInDatabase(presentationId, currentSlideId, shapeId, { delta, styles: updatedStyle }, 'shape', dispatch, lastSaveData);
          emitElementAdd({ roomId: presentationId, elementType: "shape", elementData: { shapeId, delta, style: updatedStyle, currentSlideId } });
          break;
        case 'image':
          let imgId = `copy-image-${v4()}`;
          elementData = {
            imageId: imgId,
            imageb64: imageb64,
            style: updatedStyle,
            currentSlideId: currentSlideId,
          }
          updatedSlides = getUpdatedSlides(elementData, 'image', existingSlides);
          lastSaveData = {
            slides: updatedSlides,
            mirrorSlides: mirrorSlides
          }
          dispatchAddAction(slideAction.addImage({ imageId: imgId, imageb64, style: updatedStyle, currentSlideId }), imgId, 'image');
          createNewElementInDatabase(presentationId, currentSlideId, imgId, { imageb64: imageb64, styles: updatedStyle }, 'image', dispatch, lastSaveData);
          emitElementAdd({ roomId: presentationId, elementType: "image", elementData: { imgId, imageb64, style: updatedStyle, currentSlideId } });
          break;
      }
    }
  };
  const addResizerWithTimeout = (elementId) => {
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        const handleClickOutside = (event) => {
          if (event.target !== element) {
            removeResizer(element);
            document.body.removeEventListener('click', handleClickOutside);
          }
        };
        addResizer(element);
        document.body.addEventListener('click', handleClickOutside);
      } else {
        console.error('Element not found.');
      }
    }, 0);
  };

  return (
    <>
      {documentInformation.rightType == 2 && (
        <ul className="menu" onMouseLeave={orderMenuMouseLeaveHandler} id='delete-menu'>
          {!(elementRef.current.id.includes('slide01')) && (
            <li onClick={removeElementHandler} className={disable ? "disable" : ""}>
              Delete
            </li>)}
          {!((elementRef.current.getAttribute('data-id')) || elementRef.current.id.includes('slide01')) && (
            <li
              onMouseEnter={orderMenuMouseEnterHandler}
              className={disable ? "disable" : ""}
            >
              Order
              {showOrderSubMenu && (
                <ul className="sub-menu">
                  <li onClick={moveToFrontHandler}>Move to Front</li>
                  <li onClick={moveToBackHandler}>Move to Back</li>
                </ul>
              )}
            </li>
          )}
          {(elementRef.current.id.includes('slide01') || elementRef.current.tagName === "IMG" && (
            (elementRef.current.getAttribute('data-id')))
          ) ? (
            <>
              <li onClick={addSlideHandler} className={disable ? "disable" : ""}>
                <AddIcon fontSize="small" alt={"toolbar-icon"} style={{ verticalAlign: 'middle', marginRight: '5px', marginLeft: '-5px' }} />
                <span style={{ verticalAlign: 'middle' }}>New slide</span>
              </li>
              <li onClick={() => moveSlideToBeginning()}>Move slide to beginning</li>
              <li onClick={() => moveSlideToEnd()}>Move to slide to end</li>
            </>
          ) : null}
          <li onClick={() => duplicateSlideHandler()} className={disable ? "disable" : ""}>Dublicate</li>
          <li onClick={() => copyHandler()} className={disable ? "disable" : ""}>Copy</li>
          <li onClick={() => pasteHandler()} className={!copiedData ? "disable" : ""}>Paste</li>
        </ul>
      )}
    </>
  );
};

// const CustomMenu = () => (
//     <ul className="menu">
//       <li>Login</li>
//       <li>Register</li>
//       <li>Open Profile</li>
//     </ul>
//   );