import { slideAction } from "../../store/slideSlice";
import { formatFileSize, getWorkWiseAuthToken } from "../../utils/helper";
import axios from "axios";

export const workwiseUrl = "https://workw.com/workwapi/api";
const workwiseHeaders = { 'Authorization': `Bearer ${getWorkWiseAuthToken()}` }; // workwise auth header with bearer token
let debounceTimeout;

export const workwiseServices = {
  async getDocumentById(id) {
    const dummyResponse = {
      status: 200,
      data: {
        data: {
          rightType: 2,
          members: [],
          name: "Dummy",
          creator: {
            id: "dss",
            name: "YUP",
            image: "tt"
          },
          createBy: "dsds"

        }
      }
    }

    // return dummyResponse;

    return axios.get(
      `${workwiseUrl}/Authorize/Authorize?id=${id}`,
      { headers: workwiseHeaders });
  },
  async getAllSuggestion(payload) {
    return axios.post(
      `${workwiseUrl}/Suggestion/GetAllSuggestion`,
      payload,
      { headers: workwiseHeaders });
  },
  async addDocumentMember(id, payload) {
    return axios.post(
      `${workwiseUrl}/Document/AddDocumentMember?id=${id}`,
      payload,
      { headers: workwiseHeaders });
  },
  async shareDocumentToAnyone(payload) {
    return axios.post(`${workwiseUrl}/Document/UpdateDocument`,
      payload,
      { headers: workwiseHeaders }
    )
  },
  async saveDocumentlastUpdate(id, dispatch, data) {
    if (!dispatch) {
      return;
    }
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString();
    const size = new TextEncoder().encode(JSON.stringify(data)).length || 0;
    const sizeWithUnit = formatFileSize(size);

    dispatch(slideAction.updateIsSaving({ isSaving: 'Saved', lastSavedDateTime: formattedDate, fileSize: sizeWithUnit }));

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(async () => {
      try {
        await axios.post(`${workwiseUrl}/Document/SaveDocumentAttachment?attachmentId=${id}&fileSize=${size}`,
          {}, { headers: workwiseHeaders }
        );
        // Handle the response if needed
      } catch (e) {
        console.error("Error saving document attachment:", e);
      }
    }, 5000);
  },
  async completeDocument(id) {
    try {
      const formData = new FormData();
      formData.append('id', id);

      await axios.post(`${workwiseUrl}/Document/CompleteDocument?id=${id}`,
        formData,
        { headers: workwiseHeaders }
      );
      // Handle the response if needed
    } catch (e) {
      console.error("error", e);
    }
  }
};