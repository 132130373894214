import React,{useState,useEffect} from 'react'
import './offlineStatus.css';

function OfflineStatus() {
  
  const [offline, setOffline] = useState(false);
  useEffect(() => {
    const handleOfflineStatus = () => {
        setOffline(!window.navigator.onLine);
    };

    window.addEventListener("offline", handleOfflineStatus);
    window.addEventListener("online", handleOfflineStatus);

    // Cleanup the event listeners when the component unmounts
    return () => {
        window.removeEventListener("offline", handleOfflineStatus);
        window.removeEventListener("online", handleOfflineStatus);
    };
}, []); // Empty dependency array ensures that this effect runs only once on mount

  return (
    <div style={{marginRight:'0%'}}>
      {offline &&
        <div className='offlineStatus'>
            <h5>No Internet, Your changes are not being saved</h5>
        </div>
        }
      
    </div>
  )
}

export default OfflineStatus
