import React, { useRef } from "react";
import basicCircle from '../../../../assets/shapes/basicCircle.svg'
import basicTriangle from '../../../../assets/shapes/basicTriangle.svg'
import basicRectangle from '../../../../assets/shapes/basicRectangle.svg'
import calloutRect from '../../../../assets/shapes/calloutRect.svg'
import basicStar from '../../../../assets/shapes/basicStar.svg'
import rightTriangle from '../../../../assets/shapes/rightTriangle.svg'
import cylinder from '../../../../assets/shapes/cylinder.svg';
import heart from '../../../../assets/shapes/heart.svg';
import plus from '../../../../assets/shapes/plus.svg';
import diamond  from "../../../../assets/shapes/diamond.svg";
import square2  from "../../../../assets/shapes/Squre2.svg";
import oval from '../../../../assets/shapes/oval.svg';
import singleCornerRectangle from '../../../../assets/shapes/single-corner-rectangle.svg';
import roundedRectangle from '../../../../assets/shapes/rounded-rectangle.svg';
import parallelogram from '../../../../assets/shapes/parallelogram.svg';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Quill from "quill";
import classes from "./Shape.module.css";
import useResizer from '../../../../hooks/use-resizer';
import { slideAction } from "../../../../store/slideSlice";
import { getSnapshot } from "../../../../utils/utils";
import { useParams } from "react-router-dom";
import { slideAPI } from "../../../../store/api";
import { emitShapeTextUpdate } from "../../../../Socket/Socket";
import BasicCircle from "./ShapesComp/basicCricle";
import Triangle from "./ShapesComp/basicTriangle";
import Rectangle from "./ShapesComp/rectangle";
import Oval from "./ShapesComp/oval";
import Trapezoid from "./ShapesComp/Trapezoid";
import Polygon from "./ShapesComp/polygon";
import RightAngleTriangle from "./ShapesComp/rightAngleTriangle";
import CalloutShapeSvg from "./ShapesComp/callout";
import Star from "./ShapesComp/star";
import Cylinder from "./ShapesComp/cylinder";
import debounce from 'lodash/debounce';
import LayoutEnum from "../../../../utils/layoutEnum";
import { updateTextboxDelta } from "../../../../utils/helper";


export const Shape = ({shapeId ,currentSlideIdProp=null}) => {

  const Parchment = Quill.import('parchment');
  const [selectedBgColor, setSelectedBgColor] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const lineHeightArr = [
    '1.0',
    '1.2',
    '1.5',
    '1.6',
    '1.8',
    '2.0',
    '2.4',
    '2.8',
    '3.0',
    '4.0',
    '5.0'
  ]
  const lineHeightConfig = {
    scope: Parchment.Scope.INLINE,
    whitelist: lineHeightArr
  };

  const lineHeightClass = new Parchment.Attributor.Class('lineheight', 'ql-line-height', lineHeightConfig);
  const lineHeightStyle = new Parchment.Attributor.Style('lineheight', 'line-height', lineHeightConfig);
  Parchment.register(lineHeightClass);
  Parchment.register(lineHeightStyle);

  const fontFamilyArr = ["Roboto","Roboto Condensed", "Times New Roman", "Calibri", "Calibri Light","Cambria", "Georgia", "Verdana", "Sans-Serif"];
  let fonts = Quill.import("attributors/style/font");
  fonts.whitelist = fontFamilyArr;
  Quill.register(fonts, true);

  const fontSizeArr = ['10px', '11px', '12px', '14px','16px', '18px','20px','22px', '24px','26px','28px','30px', '32px','34px', '36px','38px','40px','42px','44px','46px','48px','50px','52px','54px','56px','58px','60px','62px','64px','72px', '96px'];
  var Size = Quill.import('attributors/style/size');
  Size.whitelist = fontSizeArr;
  Quill.register(Size, true);


  const toolbarConfig = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["link"],
    [{'lineheight': lineHeightArr}],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    [{ 'size': fontSizeArr }], // custom dropdown
    // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }],
    [{ background: [] }],
    [{ 'font': fontFamilyArr }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];

  const {id} = useParams();
  const [addResizer, removeResizer, dragEvent] = useResizer();
  const [shapeText, setShapeText]=useState();
  const dispatch = useDispatch();
  const {updateShapeTextInDatabase} = slideAPI;
  const [checkCurrentSlideId, setCurrentSlideId] = useState();
  const isMasterSlide = useSelector((state) => state.slide.isMasterSlide);
  let masterSlideId = useSelector(state => state.slide.masterSlideId);
  const existingSlides = useSelector((state) => state.slide.slides);
  const mirrorSlides = useSelector((state) => state.slide.mirrorSlides);
  let currentSlideId = useSelector((state) => state.slide.currentSlideId);
  if (currentSlideIdProp) {
    currentSlideId = currentSlideIdProp;
  }
  const shapeData = useSelector((state) => state.slide.slides[currentSlideId]?.shapes[shapeId]);
  const presentMode = useSelector((state) => state.slide.presentMode);
  const shapeType = shapeId.slice(0, 5);
  const shape = shapeType === "circl" ? basicCircle : shapeType === "trian" ? basicTriangle : shapeType === "recta" ?
    basicRectangle : shapeType === "callo" ? calloutRect : shapeType === "stars" ? basicStar: shapeType === "right" ?
      rightTriangle: shapeType === "cylin" ? cylinder : shapeType === "heart" ? heart : shapeType === "pluss" ? plus
        : shapeType === "diamo" ? diamond:shapeType == "squr2" ? square2:shapeType === "ovals" ? oval:shapeType === "singl"?singleCornerRectangle
          :shapeType === "round"? roundedRectangle: shapeType === "paral"? parallelogram:"";
  const quillRef = useRef();
  // console.log('hellohape',shape);

  // useEffect(() => {
  //     // !presentMode && document.getElementById('quill'+shapeId).addEventListener('dblclick', addTextEditor, {once:true})
  // }, [presentMode]);

  const updateTextDebounce = useRef(debounce((deltaState, currentSlideId, isMasterSlide, masterSlideId,existingSlides) => {
    updateDelta(deltaState, currentSlideId, isMasterSlide, masterSlideId,existingSlides);
  }, 500)).current;

  const updateDelta = (deltaState, currentSlideId, isMasterSlide, masterSlideId,existingSlides) => {
    if (deltaState && currentSlideId && !isMasterSlide) {
      removeResizer(document.getElementById(shapeId));
      getSnapshot().then((dataUrl) => {
        if (!isMasterSlide) {
          dispatch(slideAction.updateShapeText({ slideId: currentSlideId, ...deltaState }));
          dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
          const payload = { shapeId: shapeId, content: quillRef.current.getContents(), currentSlideId: currentSlideId };
          const updatedSlides = updateTextboxDelta(existingSlides, payload, 'shape');
          const data = {
            slides: updatedSlides,
            mirrorSlides: mirrorSlides
          }
          updateShapeTextInDatabase(id, currentSlideId, shapeId, quillRef.current.getContents(),dispatch,data);
          emitShapeTextUpdate({
            roomId: id,
            elementData: {
              slideId: currentSlideId,
              content: quillRef.current.getContents(),
              shapeId: shapeId,
            },
            mirrorData: { dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }
          });
          addResizer(document.getElementById(shapeId));
        }
      })
    }
    else if (deltaState && isMasterSlide && currentSlideId.includes('master')) {
      dispatch(slideAction.updateShapeText({ slideId: masterSlideId, ...deltaState }));
      const isMainMasterSlide = !Object.values(LayoutEnum).some(layout => currentSlideId.toLowerCase().includes(layout.toLowerCase()));
      if(isMainMasterSlide){
        Object.keys(existingSlides).forEach((slideId) => {
          const isMasterLayout = Object.values(LayoutEnum).some((enumValue) =>
          slideId.toLowerCase().includes(enumValue.toLowerCase())
        );
        if (isMasterLayout) {
          dispatch(slideAction.updateShapeText({ slideId: slideId, ...deltaState }));
          }
        });
        slideAPI.updateMultipleElementTextInDatabase(id, shapeId, quillRef.current.getContents());
      }
      // handleMasterSlideUpdate(deltaState);
    }
  };

  async function handleMasterSlideUpdate(delta) {
    const slideKeys = Object.keys(existingSlides).filter(slideId => !slideId.includes('master'));
    for (let slideId of slideKeys) {
      dispatch(slideAction.updateShapeText({ slideId: slideId, ...delta }));
      dispatch(slideAction.setCurrentSlide(slideId));
      await delay(500);
      const dataUrl = await getSnapshot()
      dispatch(
        slideAction.saveMirror({
          dataId: slideId,
          mirrorSlideDataUrl: dataUrl,
        })
      );
    }
    dispatch(slideAction.setCurrentSlide(masterSlideId));
    // slideAPI.updateMultipleElementTextInDatabase(id, elementId, delta);
  }
  function delay(t) {
    return new Promise(resolve => setTimeout(resolve, t));
  }

  useEffect(() => {
    
    if (shapeText && shapeData.delta) {
      let currentContent = quillRef.current.getContents();
      if (currentContent?.ops?.[0]?.insert !== shapeData.delta?.ops?.[0]?.insert) {
        !currentSlideId.includes('master') &&  dispatch(slideAction.updateIsSaving({ isSaving: 'Saving...', lastSavedDateTime: '' }));
        updateTextDebounce(shapeText, currentSlideId, isMasterSlide, masterSlideId,existingSlides);
      }
    }
    return () => {
      updateTextDebounce.cancel();
    }
  }, [shapeText]);

  useEffect(()=>{
    if((currentSlideId && currentSlideId !== checkCurrentSlideId) || presentMode){
      let globalToolbarContainer = document.querySelector("#quillToolbar");
      const quill = new Quill(`#quill${shapeId}`, {
        theme: "snow",
        modules: { toolbar: toolbarConfig },
      });
      if(presentMode){
        quill.container.firstChild.setAttribute('contenteditable','false');
        setCurrentSlideId(null)
      } else{
        setCurrentSlideId(currentSlideId);
      }
      quill.setContents(shapeData.delta);
      quillRef.current = quill;

      quill.on('text-change', function(delta, oldDelta, source){
        if(source === "listener") {
          quill.setSelection(null);
          return
        }
        !quill.getText().includes(" ") && quill.container.addEventListener('keydown',function(e){
          if(e.key === " " || e.key === "enter"){   //capitalize first letter if textbox is empty
            let firstWord = quill.editor.delta.ops[0].insert.split('');
            let firstChar = firstWord[0].toUpperCase();
            firstWord[0] = firstChar;
            // firstWord[firstWord.length] = " ";
            quill.deleteText(0, firstWord.length-1);
            quill.insertText(0,firstWord.join(''));
            quill.deleteText(firstWord.length-1,1);
          }
        },{once: true});
        // if(oldDelta.ops[0].insert === "\n" && delta.ops[0].insert){      //capitalize first letter if textbox is empty 
        //   document.addEventListener('keypress',function(e){
        //     shift = e.shiftKey;
        // }, {once: true});
        // if(shift){
        //   let firstLeter = delta.ops[0].insert.toLocaleLowerCase();
        //   let updatedDelta = delta.delete(2).insert(firstLeter).slice(1,2);
        //   quill.updateContents(updatedDelta.delete(2));
        // }else{
        //   let firstLeter = delta.ops[0].insert.toUpperCase();
        //   let updatedDelta = delta.delete(2).insert(firstLeter).slice(1,2)
        //   quill.updateContents(updatedDelta.delete(2));
        // }
        // }
        setShapeText({content:quill.getContents(), shapeId})
      })
      quill.on("selection-change", function (range, oldRange, source) {
        if (range) {
          // if(quill.getContents().ops[0].insert.slice(0,8) === "Click to"){
          //   quill.setContents({ops: [{insert: "\n"}]});
          // }
          // addResizer(quill.container);
          globalToolbarContainer.appendChild(toolbar.container);

          toolbar.container.addEventListener(
            "mousedown",
            function (e) {
              e.preventDefault();
            },
            false
          );
          // console.log(globalToolbarContainer.childNodes.length === 2)
        } else if (range === null || !quill.hasFocus) {
          // if(quill.getContents().ops[0].insert === "\n"){
          //   let placeholder = {ops: [{insert:"\nClick to add title"}, {attributes: {header:1}, insert: "\n"}]}
          //   quill.setContents(placeholder);

          // }
          // hideToolbar(toolbar.container)
          globalToolbarContainer.children.length !== 0 &&
            globalToolbarContainer.removeChild(toolbar.container);
          removeResizer(quill.container.parentElement);
        }
      });
      let toolbar = quill.getModule("toolbar");
      toolbar.addHandler('background', function(value) {
        if (value) {
          quill.format('background', value);

          // Update the selected background color
          setSelectedBgColor(value);
        } else {
          quill.format('background', false);

          // Reset the selected background color
          setSelectedBgColor(null);
        }
      });
      globalToolbarContainer.appendChild(toolbar.container);
      globalToolbarContainer.removeChild(toolbar.container);
    }else if(currentSlideId && currentSlideId === checkCurrentSlideId && !quillRef.current.getSelection()){
      quillRef.current.setContents(shapeData.delta, "listener");
      // quillRef.current.setSelection(null);
    }
    // else{
    //   !presentMode && document.getElementById('quill'+shapeId).addEventListener('dblclick', addTextEditor, {once:true})
    // }
  },[presentMode, shapeData])
  useEffect(()=>{
    if (isSelected) {
      addResizer(document.getElementById(shapeId));
    } else {
      removeResizer(document.getElementById(shapeId));
    }
  },[isSelected])

  // const addTextEditor = (e) => {
  //   let globalToolbarContainer = document.querySelector("#quillToolbar");
  //   let shift = false;
  //   const quill = new Quill(`#quill${shapeId}`, {
  //     theme: "snow",
  //     modules: { toolbar: toolbarConfig },
  //   });
  //     presentMode && quill.container.firstChild.setAttribute('contenteditable','false');
  //     // shapeData.delta && quill.setContents(shapeData.delta);

  //     quill.on('text-change', function(delta, oldDelta, source){
  //       if(oldDelta.ops[0].insert === "\n" && delta.ops[0].insert){      //capitalize first letter if textbox is empty 
  //         document.addEventListener('keypress',function(e){
  //           shift = e.shiftKey;
  //       }, {once: true});
  //       if(shift){
  //         let firstLeter = delta.ops[0].insert.toLocaleLowerCase();
  //         let updatedDelta = delta.delete(2).insert(firstLeter).slice(1,2)
  //         quill.updateContents(updatedDelta.delete(2));
  //       }else{
  //         let firstLeter = delta.ops[0].insert.toUpperCase();
  //         let updatedDelta = delta.delete(2).insert(firstLeter).slice(1,2)
  //         quill.updateContents(updatedDelta.delete(2));
  //       }
  //       }
  //       setShapeText({content:quill.getContents(), shapeId})
  //   })
  //   quill.on("selection-change", function (range) {
  //     if (range) {
  //       // if(quill.getContents().ops[0].insert.slice(0,8) === "Click to"){
  //       //   quill.setContents({ops: [{insert: "\n"}]});
  //       // }
  //       // addResizer(quill.container);
  //       // dispatch(slideAction.setCurrentElement(shapeId));
  //       globalToolbarContainer.appendChild(toolbar.container);

  //       toolbar.container.addEventListener(
  //         "mousedown",
  //         function (e) {
  //           e.preventDefault();
  //         },
  //         false
  //       );
  //       // console.log(globalToolbarContainer.childNodes.length === 2)
  //     } else if (range === null || !quill.hasFocus) {
  //       // if(quill.getContents().ops[0].insert === "\n"){
  //       //   let placeholder = {ops: [{insert:"\nClick to add title"}, {attributes: {header:1}, insert: "\n"}]}
  //       //   quill.setContents(placeholder);

  //       // }
  //       // hideToolbar(toolbar.container)
  //       globalToolbarContainer.children.length !== 0 &&
  //         globalToolbarContainer.removeChild(toolbar.container);
  //       // removeResizer(quill.container);
  //     }
  //   });
  //   let toolbar = quill.getModule("toolbar");
  //   globalToolbarContainer.appendChild(toolbar.container);
  //   //   globalToolbarContainer.removeChild(toolbar.container);
  // };
  function calculateTop(shapeType) {
    if (shapeType === "circl"|| shapeType === "trian" || shapeType === "right") {
      return 50;
    }
    else if (shapeType === "diamo" || shapeType === "cylin") {
      return 30;
    }
    else if (shapeType === "recta" || shapeType === "ovals" ||shapeType === "stars") {
      return 40;
    }
    else {
      return 0;
    }
  }
  function calculateLeft(shapeType) {
    if (shapeType === "trian" || shapeType === "recta" || shapeType === "circl" || shapeType === "ovals"||  shapeType === "cylin") {
      return 25;
    }
    else if (shapeType === "diamo" ||  shapeType === "right" ) {
      return 10;
    }
    else if (shapeType === "stars" ||  shapeType === "" ) {
      return 30;
    } else {
      return 0;
    }
  }
  function handleShapeClick() {
    if(!presentMode)
    {
      setIsSelected(true);
    }
  }

  function handleBlur() {
    setIsSelected(false);
  }

  useEffect(() => {
    const globalToolbarContainer = document.querySelector('#quillToolbar');

    if (globalToolbarContainer.children.length !== 0) {
      globalToolbarContainer.firstElementChild.remove();
    }
  }, [currentSlideId]);
  
  return (
    <div
      id={shapeId}
      tabIndex="0"
      onMouseDown={!presentMode && dragEvent}
      onBlur={handleBlur}
      className={`${classes.circleCanvas} shape`}
      style={shapeData.styles}
      onClick={handleShapeClick}
    >
      {/* <div 
      id={shapeId}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        border: '2px solid red'
      }}> */}
      {shapeType === "circl" && <BasicCircle fill={selectedBgColor} width={100} height={100} id={shapeId}/>}
      {shapeType === "trian" && <Triangle fill={selectedBgColor} width={100} height={100} id={shapeId}/>}
      {shapeType === "recta" && <Rectangle fill={selectedBgColor} width={100} height={100} id={shapeId}/>}
      {shapeType === "ovals" && <Oval fill={selectedBgColor} width={150} height={100} id={shapeId}/>}
      {shapeType === "trape" && <Trapezoid fill={selectedBgColor} width={100} height={100} id={shapeId}/>}
      {shapeType === "diamo" && <Polygon fill={selectedBgColor} width={100} height={100} id={shapeId}/>}
      {shapeType === "right" && <RightAngleTriangle fill={selectedBgColor} width={100} height={100} id={shapeId}/>}
      {shapeType === "callo" && <CalloutShapeSvg fill={selectedBgColor} width={100} height={100} id={shapeId}/>}
      {shapeType === "stars" && <Star fill={selectedBgColor} width={110} height={150} id={shapeId}/>}
      {shapeType === "cylin" && <Cylinder fill={selectedBgColor} width={100} height={160} id={shapeId}/>}
      {/* </div>  */}
      {/* <img 
      src={shape}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: selectedBgColor?selectedBgColor:'',
      }} /> */}

      <div
        id={'quill' + shapeId}
        className="shapeText"
        style={{
          width: shapeType === "right" || shapeType === "stars" || shapeType === "cylin" ? '38%' : '50%',
          height: 'fit-content',
          position: 'absolute',
          top: `${calculateTop(shapeType)}%`,
          left: `${calculateLeft(shapeType)}%`,
          margin: 0,
          padding: 0,
          backgroundColor: 'transparent',
          // border: '2px solid red'
        }}
      >
      </div>
    </div>
  );

};
