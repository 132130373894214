import React from 'react';

const Cylinder = ({ fill, width, height, id }) => {

    return (
        <svg
            id={id}
            height='100%'
            width={'100%'}
            viewBox={`0 0 ${width} ${height}`}
            fill={fill ? fill : "#fff"}
            stroke="#000"
            strokeWidth="3"

        >
            <path
            id={id}
            d="M70.57,12.87c0,25.23-.02,53.81-.02,79.05,0,3.38,0,6.77,0,10.15,0,1.11-.39,2.07-1.03,2.95-.97,1.35-2.29,2.32-3.68,3.18-1.82,1.13-3.78,1.97-5.79,2.67-1.57,.55-3.18,1-4.8,1.41-1.48,.37-2.98,.67-4.48,.93-1.37,.24-2.74,.41-4.12,.58-.91,.12-1.83,.18-2.74,.29-1.65,.2-3.31,.19-4.95,.32-1.78,.14-3.56,.07-5.34,.09-1.25,.01-2.52-.03-3.76-.15-1.15-.11-2.31-.14-3.46-.27-1.52-.17-3.04-.33-4.55-.59-1.83-.31-3.66-.58-5.45-1.09-1.2-.34-2.44-.54-3.62-.92-2.29-.75-4.56-1.56-6.67-2.75-1.41-.79-2.76-1.66-3.87-2.85-.79-.84-1.42-1.77-1.64-2.92-.05-.28-.08-.56-.08-.84,0-3.38,0-6.77,0-10.15,0-25.21,0-50.41,0-75.62,0-.28,0-3.19,0-3.59"
            fill={fill ? fill : "#fff"}
            />
            <path
            id={id}
            d="M6.1,19.42c-.72-.35-1.42-.75-2.06-1.21-.81-.59-1.56-1.27-2.21-2.04-.82-.97-1.38-2.09-1.32-3.42,.04-1.03,.42-1.92,1.01-2.74,.99-1.37,2.32-2.35,3.74-3.22,1.8-1.11,3.74-1.94,5.73-2.64,1.6-.56,3.23-1.02,4.87-1.43,1.5-.38,3.03-.63,4.56-.92,1.11-.21,2.22-.4,3.34-.56,.63-.09,1.27-.1,1.9-.16,.68-.07,1.35-.18,2.03-.26,.03,0,.06,0,.08,0,1.06-.05,2.12-.08,3.18-.16,2.04-.16,4.07-.15,6.11-.13,1.26,.01,2.51,.06,3.77,.12s2.56,.14,3.82,.31c1.65,.22,3.31,.37,4.96,.67,1.69,.31,3.38,.56,5.04,1,1.2,.32,2.44,.54,3.62,.92,1.43,.46,2.83,1.03,4.23,1.59,1.61,.64,3.12,1.48,4.54,2.48,1.11,.79,2.12,1.69,2.81,2.87,.94,1.59,.97,3.22-.03,4.82-.91,1.46-2.19,2.55-3.63,3.45-1.49,.93-3.05,1.74-4.7,2.32-1.84,.65-3.7,1.28-5.58,1.8-1.46,.41-2.97,.63-4.46,.94-.58,.12-1.17,.24-1.75,.35-.41,.08-.83,.15-1.25,.2-.96,.11-1.92,.18-2.88,.31-1.03,.14-2.07,.19-3.1,.31-.95,.11-1.9,0-2.84,.14-1.38,.2-2.75,.05-3.83,.12-1.27,0-2.26,.07-3.23-.02-1.11-.1-2.23-.08-3.34-.14-.88-.04-1.76-.13-2.64-.23-.94-.1-1.88-.22-2.81-.37s-1.83-.24-2.74-.39c-1.11-.19-2.23-.36-3.33-.61-1.49-.34-2.98-.69-4.44-1.12-1.13-.34-2.24-.74-3.36-1.15-.44-.16-.87-.34-1.29-.55l-2.55-1.23Z"
            fill={fill ? fill : "#fff"}
            />
        </svg>
    );
};

export default Cylinder;
