import React from 'react';
import Logo from '../Logo/Logo'
import SheetName from '../SheetName/SheetName';
import MyHeader from "./Header.module.css";
import { ShareModal } from '../ShareModal/ShareModal';
import { UserAvatar } from '../Avatar/UserAvatar';
import { Present } from '../Present/Present';
import Navigations from "../Navigations/Navigations";
import { useSelector } from 'react-redux';
import { FileDropdown } from '../Navigations/FileDropdown/FileDropdown';
import DocumentMembers from '../Members/members';
import Modes from './modes.js'
import PrintSlide from '../Print/printSlide.js';
import savingIcon from '../../assets/SavingIcon.png';
import savedIcon from '../../assets/savedIcon.png';
import CompleteDocument from '../CompleteDoc/completeDoc.js';


export const Header = () => {
    const slides = useSelector(state => state.slide.slides);
    const documentInformation = useSelector((state) => state.slide.documentInformation);
    const userInformation = useSelector((state) => state.slide.userInformation);
    const savingData = useSelector((state) => state.slide.savingData);
    const isReadOnly = useSelector((state) => state.slide.isReadOnly);
    return (
        <div className={MyHeader.headerMain} id='my-header'>
            <Logo />
            {/* <div className={MyHeader.headerCol}> */}
            <div className={MyHeader.headerCol} style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
                <SheetName />
                <PrintSlide />
                {
                    !isReadOnly && <CompleteDocument />
                }
                {
                    savingData.isSaving &&
                    <div className={MyHeader.SavingSaved}>
                        {savingData.isSaving === 'Saving...' ? (
                            <img src={savingIcon} alt='Private document' style={{ width: '22px' }} />
                        ) : (
                            <img src={savedIcon} alt='Private document' style={{ width: '24px' }} />
                        )}
                        <span>
                            {savingData.isSaving}
                        </span>
                        <span>
                            {savingData.lastSavedDateTime}
                            {savingData.isSaving === 'Saved' && `, ${savingData.fileSize}`}
                        </span>
                    </div>
                }
                {/* <Navigations/>  */}
                {/* <FileDropdown /> */}
            </div>
            {/* </div> */}
            <div className={MyHeader.titleBarButtons}>
                <DocumentMembers />
                {slides && <Present />}
                {documentInformation.createBy == userInformation.userId && (
                    <>
                        <ShareModal />
                    </>
                )}
                <Modes />
                <UserAvatar />
            </div>
        </div>

    )
};


