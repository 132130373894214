import React from 'react';

const Star = ({ fill,width, height, id }) => {
    const starPath = `
    M ${width / 2} ${0.05 * height}
    L ${width / 2 + 0.1 * width} ${0.35 * height}
    L ${width * 0.95} ${0.4 * height}
    L ${width * 0.65} ${0.5 * height}
    L ${width * 0.8} ${0.75 * height}
    L ${width / 2} ${0.6 * height}
    L ${width * 0.2} ${0.75 * height}
    L ${width * 0.35} ${0.5 * height}
    L ${width * 0.05} ${0.4 * height}
    L ${width / 2 - 0.1 * width} ${0.35 * height}
    Z
  `;

  return (
    <svg
      id={id}
      width='100%'
      height='100%'
      viewBox={`0 0 ${width} ${height}`}
    >
      <path id={id} 
       fill={fill ? fill : "#fff"}
       stroke="black"
       strokeWidth="2"
      d={starPath} />
    </svg>
  );
};

export default Star;
