import React from 'react';

const Rectangle = ({ fill, width, height,id }) => {
    const pathData = `M 0 0 L 0 ${height} L ${width} ${height} L ${width} 0 L 0 0 z`;

    return (
        <svg
            id={id}
            width="100%"
            height="100%"
            viewBox={`0 0 ${width} ${height}`}
        >
            <path
                id={id}
                d={pathData}
                stroke="black"
                strokeWidth="2"
                fill={fill ? fill : "#fff"}
            />
        </svg>
    );
};

export default Rectangle;
