import Quill from 'quill'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { emitSpeakerNotes } from '../../../Socket/Socket';
import { slideAPI } from '../../../store/api';
import { slideAction } from '../../../store/slideSlice';
import classes from './speakerNotes.module.css';
import { getUpdatedSlides } from '../../../utils/helper';

export const SpeakerNotes = () => {

  const Parchment = Quill.import('parchment');
  const lineHeightArr = [
    '1.0',
    '1.2',
    '1.5',
    '1.6',
    '1.8',
    '2.0',
    '2.4',
    '2.8',
    '3.0',
    '4.0',
    '5.0'
  ]
    const lineHeightConfig = {
      scope: Parchment.Scope.INLINE,
      whitelist: lineHeightArr
    };

    const lineHeightClass = new Parchment.Attributor.Class('lineheight', 'ql-line-height', lineHeightConfig);
    const lineHeightStyle = new Parchment.Attributor.Style('lineheight', 'line-height', lineHeightConfig);
    Parchment.register(lineHeightClass);
    Parchment.register(lineHeightStyle);

const fontFamilyArr = ["Roboto","Roboto Condensed", "Times New Roman", "Calibri", "Calibri Light","Cambria", "Georgia", "Verdana", "Sans-Serif"];
let fonts = Quill.import("attributors/style/font");
fonts.whitelist = fontFamilyArr;
Quill.register(fonts, true);

const fontSizeArr = ['10px', '11px', '12px', '14px','16px', '18px','20px','22px', '24px','26px','28px','30px', '32px','34px', '36px','38px','40px','42px','44px','46px','48px','50px','52px','54px','56px','58px','60px','62px','64px','72px', '96px'];
var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

    
const toolbarConfig = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["link"],
  [{'lineheight': lineHeightArr}],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ 'size': fontSizeArr }], // custom dropdown
  // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ 'font': fontFamilyArr }],
  [{ align: [] }],
  
  ["clean"], // remove formatting button
];

  const {updateSpeakerNotesInDatabase} = slideAPI;

    const {id} = useParams();
    const [deltaState, setDeltaState] = useState();
    const quillRef = useRef();
    const dispatch = useDispatch();
    const currentSlideId = useSelector(state => state.slide.currentSlideId);
    const [checkCurrentSlideId, setCurrentSlideId] = useState();
    const speakerNotes = useSelector(state => currentSlideId && state.slide.slides[currentSlideId]?.speakerNotes);
    const isReadOnly = useSelector((state)=>state.slide.isReadOnly);
    const existingSlides = useSelector((state) => state.slide.slides);
    const mirrorSlides = useSelector((state) => state.slide.mirrorSlides);
    let toolbar;
    useEffect(() => {

      if(currentSlideId && currentSlideId !== checkCurrentSlideId){
        let globalToolbarContainer = document.querySelector('#quillToolbar');

        const notes = new Quill(`#notesQuill`, {
            theme: "snow",
            placeholder: "click to add speaker notes",
            modules: {toolbar: toolbarConfig},
          })
          quillRef.current = notes;
          notes.setContents(speakerNotes, "listener");
          setCurrentSlideId(currentSlideId);
          notes.on('text-change', function(delta, oldDelta, source){
            if(source === "listener") {
              notes.setSelection(null);
              return
            }
            !notes.getText().includes(" ") && notes.container.addEventListener('keydown',function(e){
              if(e.key === " " || e.key === "enter"){   //capitalize first letter if textbox is empty
                let firstWord = notes.editor.delta.ops[0].insert.split('');
                let firstChar = firstWord[0].toUpperCase();
                firstWord[0] = firstChar;
                // firstWord[firstWord.length] = " ";
                notes.deleteText(0, firstWord.length-1);
                notes.insertText(0,firstWord.join(''));
                notes.deleteText(firstWord.length-1,1);
              }
          },{once: true});
            // if(oldDelta.ops[0].insert === "\n" && delta.ops[0].insert){       
            //   document.addEventListener('keypress',function(e){
            //     shift = e.shiftKey;
            // }, {once: true});
            // if(shift){
            //   let firstLeter = delta.ops[0].insert.toLocaleLowerCase();
            //   let updatedDelta = delta.delete(2).insert(firstLeter).slice(1,2)
            //   notes.updateContents(updatedDelta.delete(2));
            // }else{
            //   let firstLeter = delta.ops[0].insert.toUpperCase();
            //   let updatedDelta = delta.delete(2).insert(firstLeter).slice(1,2)
            //   notes.updateContents(updatedDelta.delete(2));
            // }
            // }
              setDeltaState(notes.getContents())
            });
          notes.on('selection-change', function(range){
            if(range){
              // dispatch(slideAction.setCurrentElement('notesQuill'))
              globalToolbarContainer.appendChild(toolbar.container);
              toolbar.container.addEventListener('mousedown', function(e){
                e.preventDefault();
              },false);
              // showToolbar(toolbar.container);
            }else if(!notes.hasFocus){
              globalToolbarContainer.children.length !== 0 && globalToolbarContainer.removeChild(toolbar.container);
              // hideToolbar(toolbar.container);
            }
          });
          toolbar = notes.getModule('toolbar');
          globalToolbarContainer.appendChild(toolbar.container);
          globalToolbarContainer.removeChild(toolbar.container);
        }
        else if(currentSlideId && currentSlideId === checkCurrentSlideId && !quillRef.current.getSelection()){
          // console.log("set content runs for emiter");
            quillRef.current.setContents(speakerNotes, "listener");
            // quillRef.current.setSelection(null);
        }
         
    }, [currentSlideId, speakerNotes]);

    useEffect(() => {
      if(deltaState && deltaState !== speakerNotes){
        dispatch(slideAction.updateIsSaving({ isSaving: 'Saving...', lastSavedDateTime: '' }));
      }
        let interval = setTimeout(() =>{
          if(deltaState === speakerNotes) return
          if(deltaState){
            emitSpeakerNotes({roomId: id, notesData: {slideId: currentSlideId, content: deltaState}});
            dispatch(slideAction.addSpeakerNotes({slideId: currentSlideId, content: deltaState}));
            const updatedSlides = getUpdatedSlides({ slideId: currentSlideId, content: deltaState }, 'speakerNotes', existingSlides);
            const data = {
              slides: updatedSlides,
              mirrorSlides: mirrorSlides
            }
            updateSpeakerNotesInDatabase(id, currentSlideId, deltaState,dispatch,data);
          }
        }, 500);
        return () => {
            clearTimeout(interval)
        }
    }, [deltaState]);

    const onBlurHandler = () =>{
      let globalToolbarContainer = document.querySelector('#quillToolbar');
      globalToolbarContainer.children.length !== 0 &&  globalToolbarContainer.children[0].remove();
    }

    const mouseDownHandler = (e) => {
      const original_height = parseFloat(getComputedStyle(e.target.parentElement, null).getPropertyValue("height").replace("%", ""));
      const original_mouse_y = e.pageY;
      const container = e.target.parentElement;
      
      const handleMouseMove=(elem) => {
        const height = original_height - (elem.pageY - original_mouse_y);
        if(height/window.screen.height * 100 > 2.4){
          container.style.height = height/window.screen.height * 100 + "%";
        }
      }
      const handleMouseUp=(element) => {
        
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      }
      window.addEventListener("mousemove", handleMouseMove, false);
      window.addEventListener("mouseup", handleMouseUp, false);
    }
    const pointerEventsStyle = {
      pointerEvents: isReadOnly? "none" : "all"
    };
    return (
      <>
        {currentSlideId && <div id="speakerNoteContainer" className={classes.speakerNotesContainer} style={{...pointerEventsStyle,height: '8%'}}><div onMouseDown={mouseDownHandler} className={classes.resizer}>...</div><div tabIndex="0" id="notesQuill" onBlur={onBlurHandler}></div></div>}
      </>
    )
}
