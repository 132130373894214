import { Avatar, Badge } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import './members.css';
import { renderMouse } from '../../Socket/Socket';

const DocumentMembers = () => {
    const document = useSelector((state) => state.slide.documentInformation);
    const user = useSelector((state) => state.slide.userInformation);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const colorOptions = useMemo(() => ['#f56a00', '#7265e6', '#ffbf00', '#87d068', '#ff6b81', '#00a2ae'], []);
    const [memberColors, setMemberColors] = useState({});

    const getInitials = (name) => {
        const names = name.split(" ");
        let initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    useEffect(() => {
        renderMouse(setOnlineUsers);
        // connectedClients(setConnectedSocketID);
    }, []);

    const getRandomColor = (memberId) => {
        if (memberColors[memberId]) {
            return memberColors[memberId];
        }
        let newColor;
        do {
            newColor = '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
        } while (newColor === '#CF9FFF' || Object.values(memberColors).includes(newColor));
        setMemberColors((prevColors) => ({
            ...prevColors,
            [memberId]: newColor,
        }));
        return newColor;
    };

    return (
        <Avatar.Group
            maxCount={4}
            maxStyle={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
            }}
        >
            {document?.members?.length >= 1 && document.members.map((member, ind) => {
                if (user.userId !== member.id) {
                    const backgroundColor = getRandomColor(member.id);
                    return (
                        <Badge
                            key={ind}
                            offset={["-6%", "100%"]}
                            style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: "green"
                            }}
                            dot={onlineUsers.some(onlineUser => onlineUser.userName == member.name)}
                        >
                            <Avatar
                                src={member.image}
                                style={{
                                    backgroundColor: backgroundColor,
                                    marginLeft: '-5px',
                                    width:'30px',
                                    height:'30px',
                                    lineHeight:'30px',
                                    fontSize:'12px'
                                }}
                            >
                                {getInitials(member.name)}
                            </Avatar>
                        </Badge>
                    );
                }
            })}
        </Avatar.Group>
    );
}

export default DocumentMembers;
