import React from "react";
import { useSelector } from "react-redux";
// import { slideAction } from "../../store/slideSlice"; 
import SidebarSlideView from "./SidebarSlideView/SidebarSlideView";
// import {useParams} from "react-router-dom";
import { useDispatch } from "react-redux";
import CSSTransition from "react-transition-group/CSSTransition";
import TransitionGroup from "react-transition-group/TransitionGroup";
import './Slide.css'
// import { createPresentationById, fetchPresentationById } from "../../store/slideSlice";


const Slides = () => { // TODO( It should be slides because it contains all the slides not single slide )
  const currentSlideId = useSelector((state) => state.slide.currentSlideId); // TODO( useSelector with particular state not with whole state. it will reduce whole state change rendering to only specific connected state change )
  // const slides = useSelector((state) => state.slide.slides); // TODO( useSelector with particular state not with whole state. it will reduce whole state change rendering to only specific connected state change )
  const mirrorSlides = useSelector((state) => state.slide.mirrorSlides);
  const existingSlides = useSelector((state) => state.slide.slides);
  const filteredSlides = existingSlides && Object.keys(existingSlides).filter(id => !id.toLowerCase().includes("master"));
  // const dispatch = useDispatch();

  //const id = useParams();
  const dispatch = useDispatch();

 
  
  // React.useEffect(() => {
  //   //dispatch(createPresentationById(id));    
  //   //dispatch(fetchPresentationById(id));
  // }, [id]);

  const slideItems = mirrorSlides && filteredSlides.length > 0 && Object.keys(mirrorSlides).map((slideId,ind) => {
    if(slideId.includes('master'))
    {
      ind >0 && ind--;
      return;
    }
    return  <CSSTransition key={ind} classNames="fade" timeout={300}>
              <SidebarSlideView key={slideId} ind={ind} currentSlideId={currentSlideId} slideId={slideId} mirrorSlides={mirrorSlides} />
            </CSSTransition>
            })


  return (
    <TransitionGroup component="div" className="List">
      {slideItems}
    </TransitionGroup>
  );
};

export default Slides;
