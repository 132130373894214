import LayoutEnum from './layoutEnum';

const layoutsArray = [
    {
      key: LayoutEnum.BLANK,
      structure: {
        slideBackgroundColor: '#fff',
        textBoxIds: [],
        textBox: {},
        imageIds: [],
        image: {},
        shapes: {},
        speakerNotes: {},
      }
    },
    {
      key: LayoutEnum.TITLE_BODY,
      structure: {
        slideBackgroundColor: '#fff',
        textBoxIds: ['layoutTitle-1', 'layoutText-1'],
        textBox: {
          'layoutTitle-1': {
            delta: {
              ops: [{ insert: "Click to add title" }, { insert: "\n" }],
            },
            styles: {
              width: "93%",
              height: "11%",
              padding: "5px 10px",
              position: "absolute",
              top: "8%",
              left: "3.5%",
              pointerEvents:"none"
            },
          },
          'layoutText-1': {
            delta: {
              ops: [{ insert: "Click to add text", attributes: { color: "#5a5b60" } }, { insert: "\n" }],
            },
            styles: {
              width: "93%",
              height: "70%",
              padding: "5px 10px",
              position: "absolute",
              top: "21%",
              left: "3.5%",
              overflow: "visible",
              pointerEvents:"none"
            },
          },
        },
        imageIds: [],
        image: {},
        shapes: {},
        speakerNotes: {},
      }
    },
    {
      key: LayoutEnum.TITLE_TWO_COLUMNS,
      structure: {
        slideBackgroundColor: '#fff',
        textBoxIds: ['layoutTitle-2', 'layoutText-2', 'layoutTwo-2'],
        textBox: {
          'layoutTitle-2': {
            delta: {
              ops: [{ insert: "Click to add title" }, { insert: "\n" }],
            },
            styles: {
              width: "93%",
              height: "11%",
              padding: "5px 10px",
              position: "absolute",
              top: "8%",
              left: "3.5%",
              pointerEvents:"none"
            },
          },
          'layoutText-2': {
            delta: {
              ops: [{ insert: "Click to add text", attributes: { color: "#5a5b60" } }, { insert: "\n" }],
            },
            styles: {
              width: "44%",
              height: "70%",
              padding: "5px 10px",
              position: "absolute",
              top: "21%",
              left: "3.5%",
              overflow: "visible",
              pointerEvents:"none"
            },
          },
          'layoutTwo-2': {
            delta: {
              ops: [{ insert: "Click to add text", attributes: { color: "#5a5b60" } }, { insert: "\n" }],
            },
            styles: {
              width: "44%",
              height: "70%",
              padding: "5px 10px",
              position: "absolute",
              top: "21%",
              left: "52.5%",
              overflow: "visible",
              pointerEvents:"none"
            },
          },
        },
        imageIds: [],
        image: {},
        shapes: {},
        speakerNotes: {},
      }
    },
    {
      key: LayoutEnum.SECTION_HEADER,
      structure: {
        slideBackgroundColor: '#fff',
        textBoxIds: ['layoutTitle-3'],
        textBox: {
          'layoutTitle-3': {
            delta: {
              ops: [{ insert: "Click to add title" }, { insert: "\n" }],
            },
            styles: {
              width: '93%',
              height: '15%',
              position: 'absolute',
              top: '52%',
              left: '3.5%',
              overflow: 'visible',
              align: 'center',
              zIndex: 100,
              pointerEvents:"none"
            },
          },
        },
        imageIds: [],
        image: {},
        shapes: {},
        speakerNotes: {},
      }
    },
    {
      key: LayoutEnum.TITLE_ONE_COLUMN,
      structure: {
        slideBackgroundColor: '#fff',
        textBoxIds: ['layoutTitle-4', 'layoutText-4'],
        textBox: {
          'layoutTitle-4': {
            delta: {
              ops: [{ insert: "Click to add title" }, { insert: "\n" }],
            },
            styles: {
              width: "93%",
              height: "11%",
              padding: "5px 10px",
              position: "absolute",
              top: "8%",
              left: "3.5%",
              pointerEvents:"none"
            },
          },
          'layoutText-4': {
            delta: {
              ops: [{ insert: "Click to add text", attributes: { color: "#5a5b60" } }, { insert: "\n" }],
            },
            styles: {
              width: "44%",
              height: "70%",
              padding: "5px 10px",
              position: "absolute",
              top: "21%",
              left: "3.5%",
              overflow: "visible",
              pointerEvents:"none"
            },
          },
        },
        imageIds: [],
        image: {},
        shapes: {},
        speakerNotes: {},
      }
    },
    {
      key: LayoutEnum.TITLE_ONLY,
      structure: {
        slideBackgroundColor: '#fff',
        textBoxIds: ['layoutTitle-5'],
        textBox: {
          'layoutTitle-5': {
            delta: {
              ops: [{ insert: "Click to add title" }, { insert: "\n" }],
            },
            styles: {
              width: "93%",
              height: "11%",
              padding: "5px 10px",
              position: "absolute",
              top: "8%",
              left: "3.5%",
              pointerEvents:"none"
            },
          },
        },
        imageIds: [],
        image: {},
        shapes: {},
        speakerNotes: {},
      }
    }
  ];
  export default layoutsArray;
  