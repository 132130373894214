import { configureStore } from "@reduxjs/toolkit";

import  quillRegistryReducer  from "./quillRegistrySlice";
import slideReducer from "./slideSlice";

const store = configureStore({
  reducer: {
  slide: slideReducer,
  quillRegistry: quillRegistryReducer
},
middleware: (getDefaultMiddleware) =>
getDefaultMiddleware({
  serializableCheck: false,
}),
})



export default store;
