import React from 'react';

const Oval = ({ fill, width, height,id }) => {
  const centerX = width / 2;
  const centerY = height / 2;
  const radiusX = width / 2;
  const radiusY = height / 2;

  return (
    <svg
     id={id}
      width="100%"
      height="100%"
      viewBox={`0 0 ${width} ${height}`}
      style={{ display: 'block' }}
    >
      <path
      id={id}
      d={`M${centerX},${centerY - radiusY} 
          A${radiusX},${radiusY} 0 1,1 ${centerX},${centerY + radiusY} 
          A${radiusX},${radiusY} 0 1,1 ${centerX},${centerY - radiusY}`}
        stroke="black"
        strokeWidth="2"
        fill={fill ? fill : "#fff"}
      />
    </svg>
  );
};

export default Oval;
