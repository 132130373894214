import React from 'react';

const BasicCircle = ({ fill, width, height, id }) => {
    const cx = width / 2;
    const cy = height / 2;
    const r = Math.min(width, height) / 2;

    return (
        <svg
            id={id}
            style={{
                width: '100%',
                height: '100%',
            }}
            viewBox={`0 0 ${width} ${height}`}
        >
            <circle cx={cx} cy={cy} r={r} stroke="black" strokeWidth="2" fill={fill ? fill : "#fff"} id={id} />
        </svg>
    );
};

export default BasicCircle;
