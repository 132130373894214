import React from "react";
import { useSelector } from "react-redux";
import useResizer from "../../../../hooks/use-resizer";

export const Image = ({ imgId, currentSlideIdProp = null }) => {
  const [addResizer, removeResizer, dragEvent] = useResizer();
  let currentSlideId = useSelector((state) => state.slide.currentSlideId);
  if (currentSlideIdProp) {
    currentSlideId = currentSlideIdProp;
  }
  const imageObj = useSelector((state) => state.slide.slides[currentSlideId].image);
  const handelAddResizer = (e) => {
    addResizer(e.target.parentElement);
  }

  return (
    <div
      tabIndex="0"
      onClick={(e) => handelAddResizer(e)}
      onBlur={(e) => removeResizer(e.target)}
      key={imgId}
      id={imgId}
      className="image"
      style={imageObj[imgId] ? imageObj[imgId].styles : {}}
    >
      <img src={imageObj[imgId] ? imageObj[imgId].imageb64 : ""} onMouseDown={dragEvent} alt="..." />
    </div>
  );
};
