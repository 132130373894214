import React from 'react';

const Triangle = ({ fill, width, height, id }) => {
  const originalPoints = `0 ${height},${width} ${height},${width / 2} 0`;
  const scaleX = width / width; 
  const scaleY = height / height;

  const scaledPoints = originalPoints
    .split(',')
    .map(point => {
      const [x, y] = point.trim().split(' ');
      const scaledX = parseFloat(x) * scaleX;
      const scaledY = parseFloat(y) * scaleY;
      return `${scaledX} ${scaledY}`;
    })
    .join(',');

  return (
    <svg
      id={id}
      height='100%'
      width={'100%'}
      viewBox={`0 0 ${width} ${height}`}
    >
      <polygon
        id={id}
        points={scaledPoints}
        fill={fill ? fill : "#fff"}
        stroke="#000"
        strokeWidth="3"
      />
    </svg>
  );
};

export default Triangle;
