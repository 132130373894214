import React from 'react';

const Polygon = ({ fill, width, height,id }) => {
  const originalPoints = "0 40,40 80,80 40,40 0";
  const scaleX = width / 80;
  const scaleY = height / 80;

  return (
    <svg
      id={id}
      width="100%"
      height="100%"
      style={{ display: 'block' }}
      viewBox={`0 0 ${width} ${height}`}
    >
      <polygon
       id={id}
        points={originalPoints}
        fill={fill ? fill : "#fff"}
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Polygon;
