import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import "./App.css";
import { Header as MainHeader } from "./components/Header/Header";
import { SlideContents } from "./components/Slide/SlideContents/SlideContents";
import Slides from "./components/Slide/Slides";
import { SpeakerNotes } from "./components/Slide/SpeakerNotes/SpeakerNotes";
import { Toolbar } from "./components/Toolbar/Toolbar";
import ContextMenu from "./ContextMenu/ContextMenu";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { slideAction } from "./store/slideSlice";
import { slideAPI } from "./store/api";
import { listenElementAdd, listenElementDelete, listenShapeTextUpdate, listenSlideAdd, listenSlideColor, listenSlideDelete, listenSpeakerNotes, listenStyleUpdate, listenTextUpdate } from "./Socket/Socket";
import { AuthenticateDocument } from "./utils/AuthenticateDocument";
import { ChatBox } from "./components/ChatBox/ChatBox";
import { emitRoomID, } from "./Socket/Socket";
import MouseContainer from "./components/mousecontainer"
import OfflineStatus from "./components/OfflineStatus/OfflineStatus";
import Loader from "./components/SkeletonLoader/Loader";
import MasterSlide from "./components/MasterSlide";


const { Sider, Content } = Layout;
function App() {
  const { id } = useParams('id');
  const handleMousHover = (e) => {
    if (
      e.target.classList.contains("ql-container") ||
      e.target.classList.contains("shape")
    ) {
      e.target.style.cursor = "move";
    }
  };

  const dispatch = useDispatch();
  const { createPresentationById } = slideAPI;

  useEffect(() => {
    id && dispatch(slideAction.setPresentationId(id));
    createPresentationById(id, dispatch, setIsContentLoading);
    emitRoomID(id);
  }, [])

  useEffect(() => {
    listenSlideAdd(dispatch);
    listenElementAdd(dispatch);
    listenSlideDelete(dispatch);
    listenElementDelete(dispatch);
    listenStyleUpdate(dispatch);
    listenSlideColor(dispatch);
    listenSpeakerNotes(dispatch);
    listenTextUpdate(dispatch);
    listenShapeTextUpdate(dispatch);
  }, [])
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isContentLoading, setIsContentLoading] = useState(true);
  const isMasterSlide = useSelector((state) => state.slide.isMasterSlide);

  if (isAuthenticating) { // isAuthenticating
    return <AuthenticateDocument setIsLoading={setIsAuthenticating} />
  }
  else {
    return (
      <div className="App">
        {isContentLoading &&
          <Loader />
        }
        {!isContentLoading &&
          <>
            <MainHeader />
            <div id='offlineStatusBar'>
              <OfflineStatus />
            </div>
            <Toolbar />
            <Layout id='slide-layout'>
              <Layout>
                <Sider className="sidebar-container" width={window.screen.width < 992 ? '100%' : '13.6%'} id='my-sideBar'>
                  <Slides />
                </Sider>
                <Content id="mainContainer" className='main-container' style={{ backgroundColor: '#f4f4f5', display: 'flex', justifyContent: 'center', paddingTop: '20px', minHeight: '85vh', overflowY: 'scroll' }} onMouseOver={handleMousHover}>
                  <div id='delete-menu'>
                    <ContextMenu />
                  </div>
                  {isMasterSlide && (
                    <div id='masterSlide-Conatiner'>
                      <MasterSlide />
                    </div>
                  )}
                  <SlideContents />
                  <div id='speakerNotes'>
                    {window.screen.width > 992 && <SpeakerNotes />}

                  </div>
                </Content>
              </Layout>
            </Layout>
            <div id='chatbox-container'>
              <ChatBox />
            </div>
            <div id='mouseContainer'>
              <MouseContainer />
            </div>
          </>
        }
      </div>
    );

  }

}

export default App;
