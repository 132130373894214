import { useState } from "react";
import myPresentationName from "./SheetName.module.css";
import {useSelector } from "react-redux";
import publicIcon from '../../assets/world.svg';
import privateIcon from '../../assets/lock.svg';

const SheetName = () => {
  const [gridTitle, setGridTitle] = useState("");
  const isMobile=window.screen.width<768
  const isSmallMobile=window.screen.width<400


  const url_string = window.location.href;
  const url = new URL(url_string);
  // const mileSlidesName = url.searchParams.get("mileslidesname");
  const documentInformation = useSelector((state)=>state.slide.documentInformation);
  const mileSlidesName = documentInformation.name;
 
  // const mileSlidesName = documentInformation.

  //for sending http request
//   useEffect(() => {
//     const interval = setTimeout(() => {
//       console.log(gridTitle);
//     }, 1000);
//     return () => clearInterval(interval);
//   }, [gridTitle]);

  const handleTitleChange = (event) => {
    setGridTitle(event.target.value);
  };
  return (
    <div className={myPresentationName['title-container']} >
      <h3 style={{
          fontWeight: 600,
          width: 'max-content',
          maxWidth: '450px',
          overflow: "hidden",
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          fontSize:isMobile?isSmallMobile?'12px':'14px' :'large',
          margin:isMobile? '5px 0px 0px 5px':'4px 0px 0px 5px'

        }}>{ mileSlidesName ||"Unknown Sheet"}</h3>
      <div style={{ marginTop:isMobile?4: 7,marginLeft:10}}>
        {documentInformation.privacyId == 2 ? (
          <><img src={privateIcon} alt='Private documnet' style={{ width: '14px' }} />
          </>
        )
          : (<> <img src={publicIcon} alt='Public documnet' style={{ width: '14px' }} /></>)}
      </div>
{/*       
      <input
        className={MySheetName.title}
        value={gridTitle}
        placeholder="Untitled Slide"
        onChange={handleTitleChange}
      /> */}
    </div>
  );
};

export default SheetName;
