import React, { useEffect, useRef, useState } from "react";
import classes from "./Chats.module.css";
import sendIcon from "../../../assets/sendIcon.png";
import { useParams } from "react-router-dom";
import {
  emitLiveComments,
  listenerClients,
  listenerLiveComments,
} from "../../../Socket/Socket";
import axios from "axios";
import { useSelector } from "react-redux";
import profile from './../../../assets/profile.png'


export const Chats = ({onNewMessage}) => {
  const userInfo = useSelector((state) => state.slide.userInformation);

  const [messageArr, setMessageArr] = useState([]);
  const userEnteredMessage = useRef("");
  const { id } = useParams();
  const konn = "https://milegrid.konnect.im";

  useEffect(()=>{
    updateScroll();
    axios.post(
      `${konn}/api/update`,
      {
        spreadsheetID: id,
        Comments: messageArr,
      }
    );
  }, [id,messageArr])


  const updateScroll = () => {
    var element = document.getElementById("myChat");
    element.scrollTop = element.scrollHeight;
  };

  const sendMessage = (event) => {
    // console.log(userEnteredMessage.current.value.length);
    event.preventDefault();
    const chatData = {
      id: id,
      msg: userEnteredMessage.current.value,
      color: "#e9e9e9",
      margin: "5px 0px 5px auto",
      userInfo
    };
    if (userEnteredMessage.current.value.length !== 0) {
      updateMessageList(chatData); // this styling work should be done through uid
      emitLiveComments(chatData); // this styling work should be done through uid
    }
    userEnteredMessage.current.value = "";
  };
  const updateMessageList = (data) => {
    // * Add a slide animation class to the newly added message
    data.animateClass = userInfo.name === data.userInfo.name ? 'slide-in-left' : 'slide-in-right';
  
    setMessageArr((preState) => [...preState, data]);
  };
  React.useEffect(() => {
    listenerLiveComments(updateMessageList);
    // listenerClients();
  }, []);
  React.useEffect(()=>{
    // * The Condition will check if messageArr has msg than function passed as prop will execute which inceases count Badge
    if(messageArr.length>0)
    {
    onNewMessage()
  }
  },[messageArr])

  return (
    <div>
      <div className={classes.chats} id="myChat">
        {messageArr.map((message, ind) => {
          return (
            <div style={{display:"flex"}}>
            { userInfo.name !==message.userInfo.name &&

          <img style={{borderRadius:'50%',marginRight:5,position:"relative",top:'8px'}} width={'26px'} height={26} src={message.userInfo.image?message.userInfo.image:profile}/>}
          <div
            key={ind}
            className={`${classes["message-container"]} ${classes[message.animateClass]}`}
            style={{
              width:'max-content',
              maxWidth: "200px",
              color: "black",
              backgroundColor: message.color,
              padding: "5px 10px",
              margin: message.margin,
              borderRadius: "8px",
              fontSize: 11,
              fontWeight: 700,
            }}

          >
            { userInfo.name !==message.userInfo.name &&
            <div style={{ marginTop: -6 }}>
            <span style={{ marginTop: 0, fontSize: 10.4, color: "#fff", fontWeight: 700 }}>
              
            { userInfo.name !==message.userInfo.name?message.userInfo.name:''}
          </span> 
          </div>
          }

            { message.msg}
          </div>
          </div>
          );
        })}
      </div>
      <form onSubmit={sendMessage} className={classes["message-input"]}>
        <input
          className={classes.input}
          ref={userEnteredMessage}
          type="text"
          placeholder="Write a comment..."
        />
        <span className={classes["send-icon"]}>
          <img alt="send" src={sendIcon} onClick={sendMessage} />
        </span>
      </form>
    </div>
  );
};
