
const hardcodeToken = null;

function getCookieValue(name) {
  const regex = new RegExp(`(^| )${name}=([^;]+)`)
  const match = document.cookie.match(regex)
  if (match) {
    return match[2]
  }
}

export function getWorkWiseAuthToken() {
  const searchParams = new URLSearchParams(document.location.search);
  let cookieToken = getCookieValue("token")
  let queryToken = searchParams.get("token");
  const finalToken = queryToken || cookieToken || hardcodeToken;
  return finalToken;
}

export const isStyleChanged = (existingStyle, masterStyle) => {
  for (const styleKey of Object.keys(existingStyle)) {
    let existingKeySlice = typeof existingStyle[styleKey] === 'string' ? existingStyle[styleKey].substring(0, 5) : null;
    let masterKeySlice = typeof masterStyle[styleKey] === 'string' ? masterStyle[styleKey].substring(0, 5) : null;

    if (existingKeySlice !== null && masterKeySlice !== null) {
      if (existingKeySlice !== masterKeySlice) {
        // console.log('Slice Key (Master):', styleKey, 'Substring:', existingKeySlice, masterKeySlice);
        return true;
      }
    } else if (!isNaN(parseFloat(existingStyle[styleKey]))) {
      const existingNumValue = parseFloat(existingStyle[styleKey]).toFixed(3);
      const masterNumValue = isNaN(parseFloat(existingStyle[styleKey])) ?
        parseFloat(masterStyle[styleKey]).toFixed(3) : null;
      if (masterNumValue && existingNumValue !== masterNumValue) {
        // console.log('Slice Key (Master - Number):', styleKey, 'Value:', existingNumValue, masterNumValue);
        return true;
      }
    }
  }
  return false;
};

export const modifyMasterSlideData = (masterSlideData) => {

  if (!masterSlideData) {
    return null;
  }

  let modifiedTextBoxes = {};
  let filteredTextBoxIds = masterSlideData.textBoxIds.filter((id) => !id.includes('master'));
  for (const textBoxId of filteredTextBoxIds) {
    modifiedTextBoxes[textBoxId] = {
      ...masterSlideData.textBox[textBoxId],
      styles: { ...masterSlideData.textBox[textBoxId].styles, pointerEvents: 'none' },
    };
  }

  let modifiedShapes = {};
  for (const shapeId of Object.keys(masterSlideData.shapes)) {
    modifiedShapes[shapeId] = {
      ...masterSlideData.shapes[shapeId],
      styles: { ...masterSlideData.shapes[shapeId].styles, pointerEvents: 'none' },
    };
  }

  let modifiedImages = {};
  for (const imgId of masterSlideData.imageIds) {
    if (masterSlideData.image[imgId]) {
      modifiedImages[imgId] = {
        ...masterSlideData.image[imgId],
        styles: { ...masterSlideData.image[imgId].styles, pointerEvents: 'none' },
      };
    }
  }
  let modifiedMasterSlideData = {
    slideBackgroundColor: masterSlideData.slideBackgroundColor,
    textBoxIds: filteredTextBoxIds,
    textBox: modifiedTextBoxes,
    imageIds: [...masterSlideData.imageIds],
    image: modifiedImages,
    shapes: modifiedShapes,
    speakerNotes: masterSlideData.speakerNotes,
  };
  return modifiedMasterSlideData;
}

export const removeDuplicates = (slideData, masterSlideData) => {

  const newSlideData = {
    textBoxIds: slideData.textBoxIds.filter(id => !masterSlideData.textBoxIds.includes(id)),
    imageIds: slideData.imageIds.filter(id => !masterSlideData.imageIds.includes(id)),
    shapes: {},
    image: {},
    textBox: {}
  };


  // Add textBoxes to newSlideData
  Object.keys(slideData.textBox).forEach(textBoxId => {
    if (!masterSlideData.textBox[textBoxId]) {
      newSlideData.textBox[textBoxId] = slideData.textBox[textBoxId];
    }
  });

  // Add shapes to newSlideData
  Object.keys(slideData.shapes).forEach(shapeId => {
    if (!masterSlideData.shapes[shapeId]) {
      newSlideData.shapes[shapeId] = slideData.shapes[shapeId];
    }
  });

  // Add images to newSlideData
  Object.keys(slideData.image).forEach(imgId => {
    if (!masterSlideData.image[imgId]) {
      newSlideData.image[imgId] = slideData.image[imgId];
    }
  });
  return newSlideData;
};

export const getUpdatedSlides = (elementData, type, existingSlides) => {
  const currentSlideId = elementData.currentSlideId;
  const currentSlide = existingSlides[currentSlideId];

  switch (type) {
    case 'text': {
      const textId = elementData.textId;
      const updatedTextboxIds = [...currentSlide.textBoxIds, textId];
      const updatedTextboxes = {
        ...currentSlide.textBox,
        [textId]: {
          delta: elementData.delta ? elementData.delta : { ops: [{ insert: '' }] },
          styles: elementData.style,
        },
      };
      return {
        ...existingSlides,
        [currentSlideId]: {
          ...currentSlide,
          textBox: updatedTextboxes,
          textBoxIds: updatedTextboxIds,
        },
      };
    }
    case 'image': {
      const imageId = elementData.imageId;
      const imageb64 = elementData.imageb64;
      const style = elementData.style;
      const updatedImageIds = [...currentSlide.imageIds, imageId];
      const updatedImages = { ...currentSlide.image, [imageId]: { imageb64, styles: style } };
      return {
        ...existingSlides,
        [currentSlideId]: {
          ...currentSlide,
          image: updatedImages,
          imageIds: updatedImageIds,
        },
      };
    }
    case 'shape': {
      const shapeId = elementData.shapeId;
      const updatedShapes = {
        ...currentSlide.shapes,
        [shapeId]: {
          delta: elementData.delta ? elementData.delta : { ops: [{ insert: '' }] },
          styles: elementData.style,
        },
      };
      return {
        ...existingSlides,
        [currentSlideId]: {
          ...currentSlide,
          shapes: updatedShapes,
        },
      };
    }
    case 'slide': {
      const { slideId, slideData } = elementData;
      return {
        ...existingSlides,
        [slideId]: slideData,
      };
    }
    case 'backgroundColor': {
      const { slideId, backgroundColor } = elementData;
      return {
        ...existingSlides,
        [slideId]: {
          ...existingSlides[slideId],
          slideBackgroundColor: backgroundColor,
        },
      };
    }
    case 'speakerNotes': {
      const { slideId, content } = elementData;
      return {
        ...existingSlides,
        [slideId]: {
          ...existingSlides[slideId],
          speakerNotes: content,
        },
      };
    }
    default:
      return existingSlides;
  }
};
export const updateTextboxDelta = (existingSlides, elementData, type) => {
  const { currentSlideId, textBoxId, shapeId, content } = elementData;

  switch (type) {
    case 'textbox': {
      const currentTextBox = existingSlides[currentSlideId]?.textBox?.[textBoxId];
      if (!currentTextBox) {
        throw new Error(`TextBox with ID ${textBoxId} not found in slide ${currentSlideId}`);
      }
      const updatedTextBox = { ...currentTextBox, delta: content };
      return {
        ...existingSlides,
        [currentSlideId]: {
          ...existingSlides[currentSlideId],
          textBox: {
            ...existingSlides[currentSlideId].textBox,
            [textBoxId]: updatedTextBox,
          },
        },
      };
    }
    case 'shape': {
      const currentShape = existingSlides[currentSlideId]?.shapes?.[shapeId];
      if (!currentShape) {
        throw new Error(`Shape with ID ${shapeId} not found in slide ${currentSlideId}`);
      }
      const updatedShape = { ...currentShape, delta: content };
      return {
        ...existingSlides,
        [currentSlideId]: {
          ...existingSlides[currentSlideId],
          shapes: {
            ...existingSlides[currentSlideId].shapes,
            [shapeId]: updatedShape,
          },
        },
      };
    }
    default:
      throw new Error(`Unknown type: ${type}`);
  }
};
export const updateElementStyle = (existingSlides, elementData, type) => {
  const { currentSlideId, textBoxId, updatedStyle } = elementData;
  let updatedSlides;

  switch (type) {
    case 'text': {
      const updatedTextBox = {
        ...existingSlides[currentSlideId].textBox[textBoxId],
        styles: updatedStyle,
      };
      updatedSlides = {
        ...existingSlides,
        [currentSlideId]: {
          ...existingSlides[currentSlideId],
          textBox: {
            ...existingSlides[currentSlideId].textBox,
            [textBoxId]: updatedTextBox,
          },
        },
      };
      break;
    }
    case 'image': {
      const updatedImage = {
        ...existingSlides[currentSlideId].image[textBoxId],
        styles: updatedStyle,
      };
      updatedSlides = {
        ...existingSlides,
        [currentSlideId]: {
          ...existingSlides[currentSlideId],
          image: {
            ...existingSlides[currentSlideId].image,
            [textBoxId]: updatedImage,
          },
        },
      };
      break;
    }
    case 'shape': {
      const updatedShape = {
        ...existingSlides[currentSlideId].shapes[textBoxId],
        styles: updatedStyle,
      };
      updatedSlides = {
        ...existingSlides,
        [currentSlideId]: {
          ...existingSlides[currentSlideId],
          shapes: {
            ...existingSlides[currentSlideId].shapes,
            [textBoxId]: updatedShape,
          },
        },
      };
      break;
    }
    default:
      throw new Error(`Unknown type: ${type}`);
  }

  return updatedSlides;
};

export const deleteElement = (existingSlides, elementData, type) => {
  const { slideId, elementId } = elementData;
  const updatedSlides = JSON.parse(JSON.stringify(existingSlides)); // Deep copy to avoid mutation

  switch (type) {
    case 'textbox': {
      updatedSlides[slideId].textBoxIds = updatedSlides[slideId].textBoxIds.filter(textBox => textBox !== elementId);
      delete updatedSlides[slideId].textBox[elementId];
      break;
    }
    case 'image': {
      updatedSlides[slideId].imageIds = updatedSlides[slideId].imageIds.filter(image => image !== elementId);
      delete updatedSlides[slideId].image[elementId];
      break;
    }
    case 'shape': {
      delete updatedSlides[slideId].shapes[elementId];
      break;
    }
    case 'slide': {
      const slideKeys = Object.keys(updatedSlides);
      const slideIndex = slideKeys.indexOf(slideId);
      const nextIndex = slideIndex === slideKeys.length - 1 ? slideIndex - 1 : slideIndex + 1;
      if (updatedSlides.currentSlideId === slideId) {
        updatedSlides.currentSlideId = slideKeys[nextIndex] ? slideKeys[nextIndex] : "";
      }
      delete updatedSlides[slideId];
      break;
    }
    default:
      throw new Error(`Unknown type: ${type}`);
  }

  return updatedSlides;
};
export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0";

  const units = ["Bytes", "KB", "MB", "GB"];
  let size = bytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  if (unitIndex === 0) {
    return `${size} ${units[unitIndex]}`;
  } else if (unitIndex === 1 && size < 50) {
    return `${parseFloat(size.toFixed(0))} ${units[unitIndex]}`;
  } else {
    return `${parseFloat(size.toFixed(2))} ${units[unitIndex]}`;
  }
};
