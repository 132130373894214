import React from 'react';
import { Shape } from '../Slide/SlideContents/shapes/Shape';
import { Image } from '../Slide/SlideContents/Image/Image';
import Editor from '../Slide/SlideContents/Editor/Editor';
import { useSelector } from 'react-redux';

const MasterSlideContainer = () => {

    let currentSlideId = useSelector(state => state.slide.currentSlideId);
    let masterSlideId = useSelector(state => state.slide.masterSlideId);
    const textBoxIds = useSelector((state) => masterSlideId && state.slide.slides[masterSlideId].textBoxIds);
    const imageIds = useSelector((state) => masterSlideId && state.slide.slides[masterSlideId].imageIds);
    const shapes = useSelector((state) => masterSlideId && state.slide.slides[masterSlideId].shapes)
    const presentMode = useSelector((state) => state.slide.presentMode);
    const slideBackgroundColor = useSelector((state) => masterSlideId && state.slide.slides[masterSlideId]?.slideBackgroundColor);

    return (
        currentSlideId && (
            <div
                className='slide-container'
                id="master-slide"
                style={{
                    backgroundColor: slideBackgroundColor,
                    width: '730px',
                    height: '420px',
                }}
            >
                {textBoxIds && textBoxIds.length > 0 && currentSlideId.includes('master') &&
                    textBoxIds.map((quillId) => (
                        <Editor
                            quillId={quillId}
                            presentMode={presentMode}
                            currentSlideId={currentSlideId}
                            key={quillId}
                        />
                    ))}
                {imageIds && imageIds.length > 0 && currentSlideId.includes('master') &&
                    imageIds.map((imgId) => (
                        <Image key={imgId} imgId={imgId} />
                    ))}
                {shapes && Object.keys(shapes).length !== 0 && currentSlideId.includes('master') &&
                    Object.keys(shapes).map((shapeId) => <Shape key={shapeId} shapeId={shapeId} />)}
            </div>
        )
    )
}

export default MasterSlideContainer;