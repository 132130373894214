import React from "react";

const RightAngleTriangle = ({ fill, width, height, id }) => {
  const topPoint = `0,0`;
  const bottomLeftPoint = `0,${height}`;
  const bottomRightPoint = `${width},${height}`;

  return (
    <svg
     id={id} 
     width="100%"
     height="100%"
     viewBox={`0 0 ${width} ${height}`}>
      <path
        id={id}
        d={`M${topPoint} L${bottomLeftPoint} L${bottomRightPoint} Z`}
        fill={fill ? fill : "#fff"}
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </svg>
  );
};

export default RightAngleTriangle;
