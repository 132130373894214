import React from 'react';
import './printSlide.css';
import { useSelector } from 'react-redux';
import Editor from '../Slide/SlideContents/Editor/Editor';
import { Image } from '../Slide/SlideContents/Image/Image';
import { Shape } from '../Slide/SlideContents/shapes/Shape';
import { Toolbar } from '../Toolbar/Toolbar';

const PrintContainer = ({ forwardedRef, ...props }) => {
    const existingSlides = useSelector((state) => state.slide.slides);
    const filteredSlides = existingSlides && Object.keys(existingSlides).filter(id => !id.toLowerCase().includes("master"));

    return (
        <div className='print-container' ref={forwardedRef}>
            <Toolbar />
            {filteredSlides.length > 0 && filteredSlides.map((slideId) => {
                const slide = existingSlides[slideId] || {};
                const textBoxIds = slide?.textBoxIds || [];
                const imageIds = slide?.imageIds || [];
                const shapes = slide?.shapes || {};
                const shapeIds = Object.keys(shapes) || [];
                const slideBackgroundColor = slide?.slideBackgroundColor || '#fff';

                return (
                    <div
                        className="print-slide"
                        style={{
                            width: '1200px', height: '700px', position: 'relative',
                            overflow: 'visible', backgroundColor: slideBackgroundColor
                        }}
                    >
                        {textBoxIds.map((quillId) => (
                            <Editor
                                quillId={quillId}
                                presentMode={false}
                                currentSlideId={slideId}
                                isPrint={true}
                                printTextBox={slide.textBox[quillId]}
                            />
                        ))}
                        {imageIds.map((imgId) => (
                            <Image
                                key={imgId}
                                imgId={imgId}
                            />
                        ))}
                        {shapeIds.map((shapeId) => (
                            <Shape
                                shapeId={shapeId}
                                currentSlideIdProp={slideId}
                            />
                        ))}
                    </div>
                );
            })}
        </div>
    );
};

export default PrintContainer;
