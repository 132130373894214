import React from 'react';

const CalloutShapeSvg = ({ width, height,id }) => {
  return (
    <svg
      id={id}
      width="100%"
      height="100%"
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        id={id}
        style={{ strokeLinejoin: 'round', fillRule: 'evenodd', stroke: '#000000', strokeWidth: 2, fill: '#ffffff' }}
        d={`m${width * 0.01} ${height * 0.013} s${width * 0.937} -0.012 ${width * 0.935} -0.012 s-0.013 ${height * 0.603} -0.013 ${height * 0.828} h-${width * 0.395} l-${width * 0.065} ${height * 0.104} l-${width * 0.092} -${height * 0.102} l-${width * 0.378} 0.005 0.005 -${height * 0.609} z`}
      />
    </svg>
  );
};

export default CalloutShapeSvg;
