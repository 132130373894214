import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { emitStyleUpdate } from "../Socket/Socket";
import { slideAction } from "../store/slideSlice";
import { getSnapshot } from "../utils/utils";
import { slideAPI } from "../store/api";
import { isStyleChanged, updateElementStyle } from "../utils/helper";
import LayoutEnum from "../utils/layoutEnum";


const useResizer = () => {
  const { id } = useParams();
  const existingSlides = useSelector((state) => state.slide.slides);
  let currentSlideId = useSelector(state => state.slide.currentSlideId);
  const isMasterSlide = useSelector((state) => state.slide.isMasterSlide);
  const masterSlideId = useSelector(state => state.slide.masterSlideId);
  const presentMode = useSelector((state) => state.slide.presentMode);
  const mirrorSlides = useSelector((state) => state.slide.mirrorSlides);
  const dispatch = useDispatch();
  const pageWidth = window.screen.width > 2200 ? 1800 : window.screen.width > 1540 ? 1200 : window.screen.width > 1200 ? 960 : window.screen.width > 992 ? 848 : window.screen.width > 768 ? 700 : window.screen.width > 490 ? 490 : 300;
  const pageHeight = window.screen.width > 2200 ? 1020 : window.screen.width > 1540 ? 700 : window.screen.width > 1200 ? 540 : window.screen.width > 992 ? 500 : window.screen.width > 768 ? 400 : window.screen.width > 490 ? 290 : 180;

  const addResizer = (element) => {
    // dispatch(slideAction.setCurrentElement(element.id))
    // console.log("resizer re evaluate");
    // let element= quill.container;  
    if (element.id.includes('image') || element.id.includes('shape') ||
      element.id.includes('text') || element.id.includes('title') || element.id.includes('layout')) {
      element.classList.add('selected');
      const resizeBoxNW = document.createElement("div");
      resizeBoxNW.classList.add("resizer");
      resizeBoxNW.classList.add("top-left");
      element.appendChild(resizeBoxNW);

      const resizeBoxNE = document.createElement("div");
      resizeBoxNE.classList.add("resizer");
      resizeBoxNE.classList.add("top-right");
      element.appendChild(resizeBoxNE);

      const resizeBoxSW = document.createElement("div");
      resizeBoxSW.classList.add("resizer");
      resizeBoxSW.classList.add("bottom-left");
      element.appendChild(resizeBoxSW);

      const resizeBoxSE = document.createElement("div");
      resizeBoxSE.classList.add("resizer");
      resizeBoxSE.classList.add("bottom-right");
      element.appendChild(resizeBoxSE);

      const resizeBoxTop = document.createElement("div");
      resizeBoxTop.classList.add("resizer");
      resizeBoxTop.classList.add("top");
      // resizeBoxTop.style.left = Number(quillContainer.style.width.slice(0,quillContainer.style.width.length - 1))/2+"%";
      element.appendChild(resizeBoxTop);

      const resizeBoxRight = document.createElement("div");
      resizeBoxRight.classList.add("resizer");
      resizeBoxRight.classList.add("right");
      // resizeBoxRight.style.top = Number(quillContainer.style.height.slice(0,quillContainer.style.height.length - 1))/2 +"%";
      element.appendChild(resizeBoxRight);

      const resizeBoxBottom = document.createElement("div");
      resizeBoxBottom.classList.add("resizer");
      resizeBoxBottom.classList.add("bottom");
      // resizeBoxBottom.style.left = Number(quillContainer.style.width.slice(0,quillContainer.style.width.length - 1)) /2 +"%";
      element.appendChild(resizeBoxBottom);

      const resizeBoxLeft = document.createElement("div");
      resizeBoxLeft.classList.add("resizer");
      resizeBoxLeft.classList.add("left");
      // resizeBoxLeft.style.top = Number(quillContainer.style.height.slice(0,quillContainer.style.height.length - 1)) /2 + "%";
      element.appendChild(resizeBoxLeft);


      let resizers = document.querySelectorAll(".resizer");
      // let currentResizer;

      /// new code

      const minimum_size = 20;
      let original_width = 0;
      let original_height = 0;
      let original_x = 0;
      let original_y = 0;
      let original_mouse_x = 0;
      let original_mouse_y = 0;
      for (let i = 0; i < resizers.length; i++) {
        const currentResizer = resizers[i];
        currentResizer.addEventListener("mousedown", function (e) {
          e.stopPropagation();
          e.preventDefault();
          original_width = parseFloat(
            getComputedStyle(element, null)
              .getPropertyValue("width")
              .replace("%", "")
          );
          original_height = parseFloat(
            getComputedStyle(element, null)
              .getPropertyValue("height")
              .replace("%", "")
          );
          original_x = Number(
            element.style.left.slice(0, element.style.left.length - 1) / 100 * pageWidth
          );
          original_y = Number(
            element.style.top.slice(0, element.style.top.length - 1) / 100 * pageHeight
          );
          original_mouse_x = e.pageX;
          original_mouse_y = e.pageY;
          element.parentElement.parentElement.addEventListener("mousemove", resize);
          element.parentElement.parentElement.addEventListener("mouseup", stopResize);
        });

        function resize(e) {
          if (currentResizer.classList.contains("top")) {
            const height = original_height - (e.pageY - original_mouse_y);
            if (height > minimum_size) {
              element.style.height = height / pageHeight * 100 + "%";
              element.style.top =
                (original_y + (e.pageY - original_mouse_y)) / pageHeight * 100 + "%";
              // resizeBoxRight.style.top = ((height/ 600)* 100) / 2 + "%";
              // resizeBoxLeft.style.top = ((height/ 600)* 100) / 2 + "%";
            }
          } else if (currentResizer.classList.contains("bottom")) {
            const height = original_height - (original_mouse_y - e.pageY);
            if (height > minimum_size) {
              element.style.height = height / pageHeight * 100 + "%";
              // resizeBoxRight.style.top = ((height/ 600)* 100) / 2 + "%";
              // resizeBoxLeft.style.top = ((height/ 600)* 100) / 2 + "%";
            }
          } else if (currentResizer.classList.contains("left")) {
            const width = original_width - (e.pageX - original_mouse_x);
            if (width > minimum_size) {
              element.style.width = width / pageWidth * 100 + "%";
              element.style.left =
                (original_x + (e.pageX - original_mouse_x)) / pageWidth * 100 + "%";
            }
          } else if (currentResizer.classList.contains("right")) {
            const width = original_width - (original_mouse_x - e.pageX);
            if (width > minimum_size) {
              element.style.width = width / pageWidth * 100 + "%";
              // resizeBoxTop.style.left = ((width/1200)*100) / 2 + "%";
              // resizeBoxBottom.style.left = ((width/1200)*100) / 2 + "%";
            }
          } else if (currentResizer.classList.contains("bottom-right")) {
            const width = original_width + (e.pageX - original_mouse_x);
            const height = original_height + (e.pageY - original_mouse_y);
            if (width > minimum_size) {
              element.style.width = width / pageWidth * 100 + "%";
              // resizeBoxTop.style.left = ((width/1200)*100) / 2 + "%";
              // resizeBoxBottom.style.left = ((width/1200)*100) / 2 + "%";
            }
            if (height > minimum_size) {
              element.style.height = height / pageHeight * 100 + "%";
              // resizeBoxRight.style.top = ((height/ 600)* 100) / 2 + "%";
              // resizeBoxLeft.style.top = ((height/ 600)* 100) / 2 + "%";
            }
          } else if (currentResizer.classList.contains("bottom-left")) {
            const height = original_height + (e.pageY - original_mouse_y);
            const width = original_width - (e.pageX - original_mouse_x);
            if (height > minimum_size) {
              element.style.height = height / pageHeight * 100 + "%";
              // resizeBoxRight.style.top = ((height/ pageHeight)* 100) / 2 + "%";
              // resizeBoxLeft.style.top = ((height/ pageHeight)* 100) / 2 + "%";
            }
            if (width > minimum_size) {
              element.style.width = width / pageWidth * 100 + "%";
              element.style.left =
                (original_x + (e.pageX - original_mouse_x)) / pageWidth * 100 + "%";
              // resizeBoxTop.style.left = ((width/1200)*100) / 2 + "%";
              // resizeBoxBottom.style.left = ((width/1200)*100) / 2 + "%";
            }
          } else if (currentResizer.classList.contains("top-right")) {
            const width = original_width + (e.pageX - original_mouse_x);
            const height = original_height - (e.pageY - original_mouse_y);
            if (width > minimum_size) {
              element.style.width = width / pageWidth * 100 + "%";
              // resizeBoxTop.style.left = ((width/1200)*100) / 2 + "%";
              // resizeBoxBottom.style.left = ((width/1200)*100) / 2 + "%";
            }
            if (height > minimum_size) {
              element.style.height = height / pageHeight * 100 + "%";
              element.style.top = (original_y + (e.pageY - original_mouse_y)) / pageHeight * 100 + "%";
              // resizeBoxRight.style.top = ((height/ 600)* 100) / 2 + "%";
              // resizeBoxLeft.style.top = ((height/ 600)* 100) / 2 + "%";
            }
          } else {
            const width = original_width - (e.pageX - original_mouse_x);
            const height = original_height - (e.pageY - original_mouse_y);
            if (width > minimum_size) {
              element.style.width = width / pageWidth * 100 + "%";
              element.style.left =
                (original_x + (e.pageX - original_mouse_x)) / pageWidth * 100 + "%";
              // resizeBoxTop.style.left = ((width/1200)*100) / 2 + "%";
              // resizeBoxBottom.style.left = ((width/1200)*100) / 2 + "%";
            }
            if (height > minimum_size) {
              element.style.height = height / pageHeight * 100 + "%";
              element.style.top =
                (original_y + (e.pageY - original_mouse_y)) / pageHeight * 100 + "%";
              // resizeBoxRight.style.top = ((height/ 600)* 100) / 2 + "%";
              // resizeBoxLeft.style.top = ((height/ 600)* 100) / 2 + "%";
            }
          }
        }

        function stopResize() {
          if (isMasterSlide) {
            currentSlideId = masterSlideId;
          }
          element.parentElement.parentElement.removeEventListener("mousemove", resize);
          let cssStyle = element.getAttribute('style');
          let updatedStyle = {};
          let regex = /([\w-]*)\s*:\s*([^;]*)/g;
          let match;
          while (match = regex.exec(cssStyle)) { updatedStyle[match[1]] = match[2].trim(); }
          let eleId = element.getAttribute('id');
          const type = eleId.includes('text') || eleId.includes('layout') ? 'text' : (eleId.includes('image') ? 'image' : 'shape');
          const existingData = getExistingData(type, currentSlideId, eleId, existingSlides);
          let isChanged = existingData && isStyleChanged(existingData.styles, updatedStyle);
          isChanged && emitStyleUpdate({ roomId: id, elementData: { slideId: currentSlideId, textBoxId: element.getAttribute('id'), updatedStyle: updatedStyle } })
          if (!isMasterSlide && isChanged) {
            dispatch(slideAction.updateStyle({ slideId: currentSlideId, textBoxId: element.getAttribute('id'), updatedStyle: updatedStyle }));
            // dispatch(slideAction.updateDelta({content: quill.getContents(), activeQuillInstanceId: element.getAttribute('id')}))
            removeResizer(element);
            getSnapshot().then((dataUrl) => {
              dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
              addResizer(element);
            });
            let payload = {
              textBoxId: eleId,
              updatedStyle: updatedStyle,
              currentSlideId: currentSlideId
            }
            const updatedSlides = updateElementStyle(existingSlides, payload, type);
            const data = {
              slides: updatedSlides,
              mirrorSlides: mirrorSlides
            }
            slideAPI.updateElementStyleInDatabase(id, currentSlideId, element.getAttribute('id'), updatedStyle, type, dispatch, data);
          }
          else if (isMasterSlide && isChanged) {
            dispatch(slideAction.updateStyle({ slideId: masterSlideId, textBoxId: eleId, updatedStyle: updatedStyle }));
            const isMainMasterSlide = !Object.values(LayoutEnum).some(layout => currentSlideId.toLowerCase().includes(layout.toLowerCase()));
            if (isMainMasterSlide) {
              Object.keys(existingSlides).forEach((slideId) => {
                const isMasterLayout = Object.values(LayoutEnum).some((enumValue) =>
                  slideId.includes(enumValue)
                );
                if (isMasterLayout) {
                  dispatch(slideAction.updateStyle({ slideId: slideId, textBoxId: eleId, updatedStyle: updatedStyle }));
                }
              });
              slideAPI.updateMultipleElementStyleInDatabase(id, element.getAttribute('id'), updatedStyle, type);
            }
            // handleMasterSlideUpdate(updatedStyle, element, type);
          }
          // element.classList.add('selected');
          element.parentElement.parentElement.removeEventListener("mouseup", stopResize);
        }
      }
    }
  }

  const removeResizer = (element) => {

    if (element.id) {
      element.classList.remove('selected');
      element.querySelectorAll(".resizer").forEach((e) => e.remove());
    } else {
      element.parentElement.classList.remove('selected');
      element.parentElement.querySelectorAll(".resizer").forEach((e) => e.remove());
    }
  }

  const dragEvent = (e) => {
    // if(e.button==2){
    //   document.addEventListener('contextmenu', function(e) {

    //     e.preventDefault();
    //   }, {capture: true});
    //   document.removeEventListener('contextmenu',{capture: true});
    // }
    e.stopPropagation();
    let ele;

    // if(e.target.classList.contains("selected") && e.target.classList.contains("ql-container") && !presentMode){
    //   removeResizer(e.target);
    // }
    if ((e.target.classList.contains("ql-container") || e.target.id.includes("shape") || e.target.parentElement.classList.contains("image") || e.target.parentElement.classList.contains("shape")) && !presentMode) {
      let newX, newY, prevX, prevY, original_prevX, original_prevY;

      ele = !e.target.classList.contains('ql-container') ? document.getElementById(e.target.parentElement.id) : document.getElementById(e.target.id);
      // dispatch(slideAction.setCurrentElement(ele.id))
      ele && (ele.style.position = "absolute");
      // console.log('helloele',ele, 'helloeleparent', ele.parentElement, 'hellodoubleparent',ele.parentElement.parentElement);
      ele?.parentElement?.parentElement?.addEventListener(
        "mousemove",
        mouseMoveDrag,
        false
      );
      ele?.parentElement?.parentElement?.addEventListener("mouseup", mouseUp, false);

      original_prevX =
        (Number(ele?.style.left.slice(0, ele.style.left.length - 1)) / 100) *
        pageWidth;
      original_prevY = Number(
        (ele?.style.top.slice(0, ele.style.top.length - 1) / 100) * pageHeight
      );

      prevX = e.pageX;
      prevY = e.pageY;


      function mouseMoveDrag(e) {
        if (ele.id === 'slide01' || ele.id === 'master-slide') {
          e.preventDefault();
          return;
        } else {
          e.target.tagName === "IMG" || e.target.tagName === "svg" && e.preventDefault();
          e.stopPropagation();
          newX = e.pageX - prevX + original_prevX;
          newY = e.pageY - prevY + original_prevY;
          ele.style.left = `${(newX / pageWidth) * 100}%`;
          ele.style.top = `${(newY / pageHeight) * 100}%`;
        }
      }

      function mouseUp() {
        ele.parentElement.parentElement.removeEventListener(
          "mousemove",
          mouseMoveDrag
        );

        let cssStyle = ele.getAttribute('style');
        let updatedStyle = {};
        let regex = /([\w-]*)\s*:\s*([^;]*)/g;
        let match;
        while (match = regex.exec(cssStyle)) { updatedStyle[match[1]] = match[2].trim(); }
        // ele.classList.remove('selected');
        let eleId = ele.getAttribute('id');
        const type = eleId.includes('text') || eleId.includes('layout') ? 'text' : (eleId.includes('image') ? 'image' : 'shape');
        const existingData = getExistingData(type, currentSlideId, ele.getAttribute('id'), existingSlides);
        let isChanged = existingData && isStyleChanged(existingData.styles, updatedStyle);
        removeResizer(ele);
        isChanged && emitStyleUpdate({ roomId: id, elementData: { slideId: currentSlideId, textBoxId: ele.getAttribute('id'), updatedStyle: updatedStyle } });
        if (!isMasterSlide && isChanged) {
          getSnapshot().then((dataUrl) => {
            // emitStyleUpdate({ roomId: id, elementData: { slideId: currentSlideId, textBoxId: ele.getAttribute('id'), updatedStyle: updatedStyle } });
            dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
            dispatch(slideAction.updateStyle({ slideId: currentSlideId, textBoxId: eleId, updatedStyle: updatedStyle }));
            if (!ele.id.includes('slide01') || !ele.id.includes('master-slide')) {
              addResizer(ele);
            }
          });
          let payload = {
            textBoxId: eleId,
            updatedStyle: updatedStyle,
            currentSlideId: currentSlideId
          }
          const updatedSlides = updateElementStyle(existingSlides, payload, type);
          const data = {
            slides: updatedSlides,
            mirrorSlides: mirrorSlides
          }
          slideAPI.updateElementStyleInDatabase(id, currentSlideId, eleId, updatedStyle, type, dispatch, data);
        }
        else if (isMasterSlide && isChanged) {
          if (!ele.id.includes('slide01') || !ele.id.includes('master-slide')) {
            addResizer(ele);
          }
          dispatch(slideAction.updateStyle({ slideId: masterSlideId, textBoxId: eleId, updatedStyle: updatedStyle }));
          const isMainMasterSlide = !Object.values(LayoutEnum).some(layout => currentSlideId.toLowerCase().includes(layout.toLowerCase()));
          if (isMainMasterSlide) {
            Object.keys(existingSlides).forEach((slideId) => {
              const isMasterLayout = Object.values(LayoutEnum).some((enumValue) =>
                slideId.includes(enumValue)
              );
              if (isMasterLayout) {
                dispatch(slideAction.updateStyle({ slideId: slideId, textBoxId: eleId, updatedStyle: updatedStyle }));
              }
            });
            slideAPI.updateMultipleElementStyleInDatabase(id, ele.getAttribute('id'), updatedStyle, type);
          }
          // handleMasterSlideUpdate(updatedStyle, ele, type);
        }
        ele.parentElement.parentElement.removeEventListener(
          "mouseup",
          mouseUp
        );
      }
    }
  }

  function delay(t) {
    return new Promise(resolve => setTimeout(resolve, t));
  }

  async function handleMasterSlideUpdate(updatedStyle, ele, type) {
    let mirrorDataArray = [];
    let eleId = ele.getAttribute('id');
    const slideKeys = Object.keys(existingSlides).filter(slideId => !slideId.includes('master'));
    for (let slideId of slideKeys) {
      try {
        dispatch(slideAction.updateStyle({
          slideId: slideId,
          textBoxId: eleId,
          updatedStyle: {
            ...updatedStyle,
            pointerEvents: 'none'
          },
        }));
        dispatch(slideAction.setCurrentSlide(slideId));
        await delay(500);
        const dataUrl = await getSnapshot();
        dispatch(
          slideAction.saveMirror({
            dataId: slideId,
            mirrorSlideDataUrl: dataUrl,
          })
        );
        mirrorDataArray.push({
          imageId: slideId,
          b64Data: dataUrl,
        })
      }
      catch (err) {
        console.error('Crash....', err);
      }
    }
    slideAPI.updateMultipleElementStyleInDatabase(id, ele.getAttribute('id'), updatedStyle, type);
    slideAPI.saveMultipleMirrorInDatabase(id, mirrorDataArray);
    dispatch(slideAction.setCurrentSlide(masterSlideId));
  }

  function getExistingData(type, currentSlideId, eleId, existingSlides) {
    switch (type) {
      case 'text':
        return existingSlides[currentSlideId].textBox[eleId] || null;
      case 'image':
        return existingSlides[currentSlideId].image[eleId] || null;
      case 'shape':
        return existingSlides[currentSlideId].shapes[eleId] || null;
      default:
        return null;
    }
  }

  return [addResizer, removeResizer, dragEvent]
}

export default useResizer;