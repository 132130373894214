import Quill from "quill";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useResizer from "../../../../hooks/use-resizer";
import { saveToDatabase, slideAction } from "../../../../store/slideSlice";
import { getSnapshot } from "../../../../utils/utils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { slideAPI } from "../../../../store/api";
import { emitTextUpdate } from "../../../../Socket/Socket";
import './Editor.css';
import debounce from 'lodash/debounce';
import LayoutEnum from "../../../../utils/layoutEnum";
import { current } from "@reduxjs/toolkit";
import { updateTextboxDelta } from "../../../../utils/helper";


const Editor = ({ quillId, presentMode, currentSlideId, printTextBox = null }) => {

  const Parchment = Quill.import('parchment');
  const lineHeightArr = [
    '1.0',
    '1.2',
    '1.5',
    '1.6',
    '1.8',
    '2.0',
    '2.4',
    '2.8',
    '3.0',
    '4.0',
    '5.0'
  ];
  const lineHeightConfig = {
    scope: Parchment.Scope.INLINE,
    whitelist: lineHeightArr
  };

  const lineHeightClass = new Parchment.Attributor.Class('lineheight', 'ql-line-height', lineHeightConfig);
  const lineHeightStyle = new Parchment.Attributor.Style('lineheight', 'line-height', lineHeightConfig);
  Parchment.register(lineHeightClass);
  Parchment.register(lineHeightStyle);


  const fontFamilyArr = ["Roboto", "Raleway", "Roboto Condensed", "Times New Roman", "Calibri", "Calibri Light", "Cambria", "Georgia", "Verdana", "Sans-Serif"];
  let fonts = Quill.import("attributors/style/font");
  fonts.whitelist = fontFamilyArr;
  Quill.register(fonts, true);

  const fontSizeArr = ['10px', '11px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px', '30px', '32px', '34px', '36px', '38px', false, '44px', '46px', '48px', '50px', '52px', '54px', '56px', '58px', '60px', '62px', '64px', '72px', '96px'];
  var Size = Quill.import('attributors/style/size');
  Size.whitelist = fontSizeArr;
  Quill.register(Size, true);


  const toolbarConfig = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["link"],
    [{ 'lineheight': lineHeightArr }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    [{ 'size': fontSizeArr }], // custom dropdown
    // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ 'font': fontFamilyArr }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];
  // const currentSlideId = useSelector((state) => state.slide.currentSlideId);
  const isMasterSlide = useSelector((state) => state.slide.isMasterSlide);
  const existingSlides = useSelector((state) => state.slide.slides);
  let textBox = useSelector((state) => state.slide.slides[state.slide.currentSlideId].textBox[quillId]);
  const mirrorSlides = useSelector((state) => state.slide.mirrorSlides);
  if (printTextBox) {
    textBox = printTextBox;
  }
  let masterSlideId = useSelector(state => state.slide.masterSlideId)
  const [deltaState, setDeltaState] = useState();
  const [defaultText, setdefaultText] = useState('');
  const [checkCurrentSlideId, setCurrentSlideId] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { updateElementTextInDatabase } = slideAPI
  const [addResizer, removeResizer, dragEvent] = useResizer();
  const quillRef = useRef();
  const [isMasterLayoutElemet, setIsMasterLayoutElemet] = useState(false);

  useEffect(() => {
    if (currentSlideId && currentSlideId.includes('master')) {
      let isMasterLayoutSlide = Object.values(LayoutEnum).some(layout => currentSlideId.toLowerCase().includes(layout.toLowerCase()));
      if (isMasterLayoutSlide && quillId.includes('layout')) {
        setIsMasterLayoutElemet(true);
      }
    }
  }, [quillId, currentSlideId]);

  useEffect(() => {
    if ((currentSlideId && currentSlideId !== checkCurrentSlideId) || presentMode) {
      let globalToolbarContainer = document.querySelector('#quillToolbar');

      const quill = new Quill(`#${quillId}`, {
        theme: "snow",
        modules: { toolbar: toolbarConfig },
      })
      quillRef.current = quill;
      if (isMasterLayoutElemet) {
        quill.container.firstChild.setAttribute('contenteditable', 'false');
      }
      if (presentMode) {
        quill.container.firstChild.setAttribute('contenteditable', 'false');
        setCurrentSlideId(null)
      } else {
        setCurrentSlideId(currentSlideId);
      }
      quill.setContents(textBox.delta);
      // quill.container.classList.add("border");

      if (quill.editor.delta.ops[0].insert.slice(0, 8) !== "Click to") {
        quill.container.classList.remove("border");
      }
      if ((quill.editor.delta.ops[0].insert === "\n" || quill.editor.delta.ops[0].insert.slice(0, 8) === "Click to") && !presentMode) {
        quill.container.classList.add("border");
      }
      !presentMode && quill.on('text-change', function (delta, oldDelta, source) {
        if (source === "listener") {
          quill.setSelection(null);
          return
        }
        if (quill.editor.delta.ops[0].insert.slice(0, 8) !== "Click to") {
          quill.container.classList.remove("border");
        } else if (quill.editor.delta.ops[0].insert.slice(0, 8) === "Click to") {
          quill.container.classList.add("border");
        }
        !quill.getText().includes(" ") && quill.container.addEventListener('keydown', function (e) {
          if (e.key === " " || e.key === "enter") {
            let firstWord = quill.editor.delta.ops[0].insert.split('');
            let firstChar = firstWord[0].toUpperCase();
            firstWord[0] = firstChar;
            // firstWord[firstWord.length] = " ";
            quill.deleteText(0, firstWord.length - 1);
            quill.insertText(0, firstWord.join(''));
            quill.deleteText(firstWord.length - 1, 1);
          }
        }, { once: true });

        // if(oldDelta.ops[0].insert === "\n" && delta.ops[0].insert){      //capitalize first letter if textbox is empty 

        //   document.addEventListener('keypress',function(e){
        //     shift = e.shiftKey;
        // }, {once: true});
        // if(shift){
        //   let firstLeter = delta.ops[0].insert.toLocaleLowerCase();
        //   let updatedDelta = delta.delete(2).insert(firstLeter).slice(1,2)
        //   quill.updateContents(updatedDelta.delete(2));
        // }else{
        //   let firstLeter = delta.ops[0].insert.toUpperCase();
        //   let updatedDelta = delta.delete(2).insert(firstLeter).slice(1,2);
        //   quill.updateContents(updatedDelta.delete(2));
        // }
        // }
        if (quill.getContents().ops[0].insert.slice(0, 8) !== "Click to") {
          if ((isMasterSlide && currentSlideId.includes('master')) || (!isMasterSlide && !currentSlideId.includes('master'))) {
            setDeltaState({ content: quill.getContents(), activeQuillInstanceId: quillId, activeQuill: quill })
          }
        }
        // quill.editor.delta.ops[0].insert === "\n" && quill.container.classList.remove("border"); 
      })
      !presentMode && quill.on('selection-change', function (range, oldRange, source) {
        if (range) {
          if (quill.getContents().ops[0].insert.slice(0, 8) === "Click to" && !isMasterLayoutElemet) {
            setdefaultText(textBox.delta);
            quill.setContents({ ops: [{ insert: "\n" }] });
          }
          // console.log("inside Range text box");
          // addResizer(quill.container);
          globalToolbarContainer.appendChild(toolbar.container);

          toolbar.container.addEventListener('mousedown', function (e) {
            e.preventDefault();
          }, false);
          // console.log(globalToolbarContainer.childNodes.length === 2)
        } else if (range === null || !quill.hasFocus) {
          // if(quill.getContents().ops[0].insert === "\n"){
          //   let placeholder = {ops: [{insert:"\nClick to add title"}, {attributes: {header:1}, insert: "\n"}]}
          //   quill.setContents(placeholder);
          // console.log("outside range text");
          // }
          // hideToolbar(toolbar.container);
          // globalToolbarContainer.children.length !== 0 && globalToolbarContainer.removeChild(toolbar.container);
          // globalToolbarContainer.children.length !== 0 &&  globalToolbarContainer.children[0].remove(); 
          // removeResizer(quill.container);
        }
      })
      let toolbar = quill.getModule('toolbar');
      globalToolbarContainer.appendChild(toolbar.container);
      globalToolbarContainer.removeChild(toolbar.container);

      // // search for existing toolbar and hide it
      // let prevToolbar = toolbar.container.previousElementSibling;
      // if (prevToolbar) {
      //   hideToolbar(prevToolbar)
      // }
    }
    else if (currentSlideId && currentSlideId === checkCurrentSlideId && !quillRef.current.getSelection()) {
      quillRef.current.setContents(textBox.delta, 'listener');
      // quillRef.current.setSelection(null);
    }
  }, [presentMode, textBox])

  useEffect(() => {
    const globalToolbarContainer = document.querySelector('#quillToolbar');

    if (globalToolbarContainer.children.length !== 0) {
      globalToolbarContainer.firstElementChild.remove();
    }
  }, [currentSlideId]);

  const updateTextDebounce = useRef(debounce((deltaState, currentSlideId, isMasterSlide, masterSlideId, existingSlides) => {
    updateDelta(deltaState, currentSlideId, isMasterSlide, masterSlideId, existingSlides);
  }, 500)).current;

  const updateDelta = (deltaState, currentSlideId, isMasterSlide, masterSlideId, existingSlides) => {
    if (deltaState && currentSlideId && !isMasterSlide) {
      removeResizer(deltaState.activeQuill.container);
      getSnapshot().then((dataUrl) => {
        dispatch(slideAction.updateDelta({ slideId: currentSlideId, activeQuillInstanceId: quillId, content: quillRef.current.getContents() }));
        dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
        const payload = { textBoxId: quillId, content: quillRef.current.getContents(), currentSlideId: currentSlideId };
        const updatedSlides = updateTextboxDelta(existingSlides, payload,'textbox');
        const data = {
          slides: updatedSlides,
          mirrorSlides: mirrorSlides
        }
        updateElementTextInDatabase(id, currentSlideId, quillId, quillRef.current.getContents(), dispatch, data);
        emitTextUpdate({
          roomId: id,
          elementData: { slideId: currentSlideId, activeQuillInstanceId: quillId, content: quillRef.current.getContents() },
          mirrorData: { dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }
        });
      });
    }
    else if (deltaState && isMasterSlide && currentSlideId.includes('master')) {
      removeResizer(deltaState.activeQuill.container);
      const isMainMasterSlide = !Object.values(LayoutEnum).some(layout => currentSlideId.toLowerCase().includes(layout.toLowerCase()));
      dispatch(slideAction.updateDelta({ slideId: masterSlideId, activeQuillInstanceId: quillId, content: quillRef.current.getContents() }));
      if (isMainMasterSlide) {
        Object.keys(existingSlides).forEach((slideId) => {
          const isMasterLayout = Object.values(LayoutEnum).some((enumValue) =>
            slideId.toLowerCase().includes(enumValue.toLowerCase())
          );
          if (isMasterLayout) {
            dispatch(slideAction.updateDelta({ slideId: slideId, activeQuillInstanceId: quillId, content: quillRef.current.getContents() }));
          }
        });
        slideAPI.updateMultipleElementTextInDatabase(id, quillId, quillRef.current.getContents());
      }
      // handleMasterSlideUpdate(quillId, quillRef.current.getContents());
    }
  };

  useEffect(() => {
    if (deltaState && textBox.delta && !currentSlideId.includes('master')) {
      let currentContent = quillRef.current.getContents();
      if (currentContent?.ops?.[0]?.insert !== textBox.delta?.ops?.[0]?.insert) {
        dispatch(slideAction.updateIsSaving({ isSaving: 'Saving...', lastSavedDateTime: '' }));
      }
    }
    updateTextDebounce(deltaState, currentSlideId, isMasterSlide, masterSlideId, existingSlides);
    // if (deltaState && isMasterSlide) {
    //   addResizer(deltaState.activeQuill.container);
    //   dispatch(slideAction.updateDelta(
    //     {
    //       slideId: currentSlideId,
    //       activeQuillInstanceId: quillId,
    //       content: quillRef.current.getContents()
    //     }
    //   ));
    // }
    return () => {
      updateTextDebounce.cancel();
    }
  }, [deltaState]);


  // useEffect(()=>{
  //     quillRef.current.setContents(textBox.delta, "api");
  //     quillRef.current.setSelection(quillRef.current.getLength());
  // }, [textBox]);

  // const keyDownHandler = (e) =>{
  //   if(e.keyCode == 46){
  //     e.preventDefault();
  //     dispatch(slideAction.removeTextBox({slideId:currentSlideId,textBoxId: quillId}))
  //   }
  // }
  const handleOnBlur = (e) => {
    let globalToolbarContainer = document.querySelector('#quillToolbar');
    if (textBox?.delta?.ops[0]?.insert && textBox?.delta?.ops[0]?.insert === '\n') {
      quillRef.current.setContents(defaultText);
      getSnapshot().then((dataUrl) => {
        dispatch(slideAction.updateDelta({ slideId: currentSlideId, activeQuillInstanceId: quillId, content: quillRef.current.getContents() }));
        dispatch(slideAction.saveMirror({ dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }));
        emitTextUpdate({
          roomId: id,
          elementData: { slideId: currentSlideId, activeQuillInstanceId: quillId, content: quillRef.current.getContents() },
          mirrorData: { dataId: currentSlideId, mirrorSlideDataUrl: dataUrl }
        });
        const payload = { textBoxId: quillId, content: quillRef.current.getContents(), currentSlideId: currentSlideId };
        const updatedSlides = updateTextboxDelta(existingSlides, payload,'textbox');
        const data = {
          slides: updatedSlides,
          mirrorSlides: mirrorSlides
        }
        updateElementTextInDatabase(id, currentSlideId, quillId, quillRef.current.getContents(), dispatch,data);
      });
    }
    removeResizer(e.target);
    globalToolbarContainer.children.length !== 0 && globalToolbarContainer.children[0].remove();
  };

  const handleOnFocus = (element) => {
    !element.target.id && addResizer(element.target.parentElement);
  }

  async function handleMasterSlideUpdate(elementId, delta) {
    const slideKeys = Object.keys(existingSlides).filter(slideId => !slideId.includes('master'));
    // dispatch(slideAction.setCurrentSlide(masterSlideId));
    for (let slideId of slideKeys) {
      dispatch(slideAction.updateDelta({ slideId: slideId, activeQuillInstanceId: elementId, content: delta }));
      dispatch(slideAction.setCurrentSlide(slideId));
      await delay(500);
      const dataUrl = await getSnapshot()
      dispatch(
        slideAction.saveMirror({
          dataId: slideId,
          mirrorSlideDataUrl: dataUrl,
        })
      );
    }
    dispatch(slideAction.setCurrentSlide(masterSlideId));
    slideAPI.updateMultipleElementTextInDatabase(id, elementId, delta);
  }
  function delay(t) {
    return new Promise(resolve => setTimeout(resolve, t));
  }

  return (
    <div
      tabIndex="0"
      onDragOver={e => e.preventDefault()}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      id={quillId}
      key={quillId}
      onMouseDown={dragEvent}
      className={quillId.slice(0, 4) === 'text' ? 'text' : 'title'}
      style={textBox?.styles}
    />
  );
};

export default Editor;
