import React, { useEffect, useRef, useState } from 'react';
import './index.css'
import { CloseOutlined } from '@mui/icons-material';
import slideSlice, { slideAction } from '../../store/slideSlice';
import { useDispatch, useSelector } from 'react-redux';
import Editor from '../Slide/SlideContents/Editor/Editor';
import { Image } from '../Slide/SlideContents/Image/Image';
import { Shape } from '../Slide/SlideContents/shapes/Shape';
import { Button, Spin } from 'antd';
import { getSnapshot } from '../../utils/utils';
import { modifyMasterSlideData } from '../../utils/helper';
import { slideAPI } from '../../store/api';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import MasterSlideContainer from './masterSlideContainer';
import { Layout } from "antd";
import MasterSlideSideBar from './sideBar';
import LayoutEnum from '../../utils/layoutEnum';
import { workwiseServices } from '../WorkW-Api/workWiseApi';
const { Sider, Content } = Layout;

const MasterSlide = () => {

    const { id } = useParams();
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    let currentSlideId = useSelector(state => state.slide.currentSlideId);
    let masterSlideId = useSelector(state => state.slide.masterSlideId);
    const textBoxIds = useSelector((state) => masterSlideId && state.slide.slides[masterSlideId].textBoxIds);
    const imageIds = useSelector((state) => masterSlideId && state.slide.slides[masterSlideId].imageIds);
    const shapes = useSelector((state) => masterSlideId && state.slide.slides[masterSlideId].shapes)
    const presentMode = useSelector((state) => state.slide.presentMode);
    const slideBackgroundColor = useSelector((state) => masterSlideId && state.slide.slides[masterSlideId]?.slideBackgroundColor);
    const existingSlides = useSelector((state) => state.slide.slides);
    const mirrorSlides = useSelector((state) => state.slide.mirrorSlides);
    const [isProcessing, setIsprocessing] = useState(null);
    const deletedElements = useSelector((state) => state.slide.deletedElements);
    const filteredSlides = existingSlides && Object.keys(existingSlides).filter(id => !id.toLowerCase().includes("master"));
    const [selectedSlideLayout, setSelectedSlideLayout] = useState(null);
    const sidebarRef = useRef(null);
    const scrollPositionRef = useRef(0);

    const hideModal = () => {

        let lastSlideId;
        if (currentSlideId.toLowerCase().includes("master")) {
            const slideIds = Object.keys(existingSlides);
            let i = slideIds.length - 1;
            while (i >= 0 && slideIds[i].toLowerCase().includes("master")) {
                i--;
            }
            if (i >= 0) {
                lastSlideId = slideIds[i];
            }
        }
        let deletedElementsId = Object.keys(deletedElements);
        if (deletedElementsId.length > 0) {
            for (const id of deletedElementsId) {
                let masterElement = deletedElements[id];
                if (id.includes('text') || id.includes('title') || id.includes('layout')) {
                    let payload = {
                        delta: masterElement.delta,
                        textId: id,
                        style: masterElement.styles,
                        currentSlideId: masterSlideId,
                    }
                    dispatch(slideAction.addTextBox(payload));
                    dispatch(slideAction.removeDeletedElementId(id));
                }
                else if (id.includes('image')) {
                    let payload = {
                        imageb64: masterElement.imageb64,
                        imageId: id,
                        style: masterElement.styles,
                        currentSlideId: masterSlideId,
                    }
                    dispatch(slideAction.addImage(payload));
                    dispatch(slideAction.removeDeletedElementId(id));
                }
                else if (id.includes('shape')) {
                    let payload = {
                        delta: masterElement.delta,
                        shapeId: id,
                        style: masterElement.styles,
                        currentSlideId: masterSlideId,
                    }
                    dispatch(slideAction.addShape(payload));
                    dispatch(slideAction.removeDeletedElementId(id));
                };
            }
        }
        dispatch(slideAction.setIsMasterSlide(false));
        lastSlideId && dispatch(slideAction.setCurrentSlide(lastSlideId));
    };

    useEffect(() => {
        const handleClickEvent = (e) => {
            let toolbar = document.getElementById('my-toolbar');
            let masterModal = document.getElementById('master-slide-container');
            let ismasterModal = masterModal && (e.target == masterModal || masterModal.contains(e.target));
            let isToolbarClick = toolbar && (e.target === toolbar || toolbar.contains(e.target));
            let layoutButton = document.getElementById('layout-btn');
            let isLayoutClick = layoutButton && (e.target === layoutButton || layoutButton.contains(e.target));
            let bgColorDropdown = document.getElementById('bg-color-dropdwon');
            let isBgDropdownClick = bgColorDropdown && (e.target === bgColorDropdown || bgColorDropdown.contains(e.target));
            let shapeMenu = document.getElementById('shapes-menu');
            let deleteMenu = document.getElementById('delete-menu');
            let isDeleteMenuClick = deleteMenu && (e.target === deleteMenu || deleteMenu.contains(e.target));
            let isShapeMenuItemClick = shapeMenu && (e.target === shapeMenu || shapeMenu.contains(e.target));
            if (!ismasterModal && !isToolbarClick && !isBgDropdownClick && !isShapeMenuItemClick && !isDeleteMenuClick
                || isLayoutClick) {
                setTimeout(() => {
                    hideModal();
                }, 500);
            }
        };
        document.addEventListener('click', handleClickEvent);
        return () => {
            document.removeEventListener('click', handleClickEvent);
        };
    }, []);

    const updateAllSlides = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (filteredSlides.length > 0) {
            // handleMasterSlideUpdate();
            setIsprocessing(true);
        }
    }
    async function handleMasterSlideUpdate() {
        dispatch(slideAction.updateIsSaving({ isSaving: 'Saving...', lastSavedDateTime: '' }));
        let mirrorDataArray = [];
        const slideKeys = Object.keys(existingSlides).filter(slideId => !slideId.includes('master'));
        const masterSlideModifiedData = modifyMasterSlideData(existingSlides[masterSlideId] || null);
        const isMasterLayoutSlide = Object.values(LayoutEnum).some(layout => currentSlideId.toLowerCase().includes(layout.toLowerCase()));
        if (isMasterLayoutSlide) {
            const slideKeys = Object.keys(existingSlides);
            if (slideKeys.length > 0) {
                for (const slideId of slideKeys) {
                    const slideData = existingSlides[slideId];
                    if (slideData.layoutType === selectedSlideLayout) {
                        await updateSlideBackground(slideId, slideData, masterSlideModifiedData.slideBackgroundColor);
                        await updateElements('textbox', slideId, slideData, masterSlideModifiedData.textBoxIds, masterSlideModifiedData.textBox);
                        await updateElements('image', slideId, slideData, masterSlideModifiedData.imageIds, masterSlideModifiedData.image);
                        await updateElements('shape', slideId, slideData, Object.keys(masterSlideModifiedData.shapes), masterSlideModifiedData.shapes);
                        dispatch(slideAction.setCurrentSlide(slideId));
                        await delay(500);
                        const dataUrl = await getSnapshot();
                        dispatch(
                            slideAction.saveMirror({
                                dataId: slideId,
                                mirrorSlideDataUrl: dataUrl,
                            })
                        );
                    }
                }
            }
            dispatch(slideAction.setCurrentSlide(masterSlideId));
            // slideAPI.saveMultipleMirrorInDatabase(id, mirrorDataArray); cant call it rplace the miirors with only layout miior
        }
        else {
            for (const slideId of slideKeys) {
                try {
                    const slideData = existingSlides[slideId];

                    await updateSlideBackground(slideId, slideData, masterSlideModifiedData.slideBackgroundColor);
                    await updateElements('textbox', slideId, slideData, masterSlideModifiedData.textBoxIds, masterSlideModifiedData.textBox);
                    await updateElements('image', slideId, slideData, masterSlideModifiedData.imageIds, masterSlideModifiedData.image);
                    await updateElements('shape', slideId, slideData, Object.keys(masterSlideModifiedData.shapes), masterSlideModifiedData.shapes);

                    dispatch(slideAction.setCurrentSlide(slideId));
                    await delay(500);
                    const dataUrl = await getSnapshot();
                    dispatch(
                        slideAction.saveMirror({
                            dataId: slideId,
                            mirrorSlideDataUrl: dataUrl,
                        })
                    );
                    mirrorDataArray.push({ imageId: slideId, b64Data: dataUrl });
                } catch (err) {
                    console.error('okkkError accour', err);
                }
            }
            dispatch(slideAction.setCurrentSlide(masterSlideId));
            slideAPI.saveMultipleMirrorInDatabase(id, mirrorDataArray);
        }
        setIsprocessing(false);
    }

    async function updateElements(type, slideId, slideData, masterIds, masterData) {
        let deletedElementsId = Object.keys(deletedElements);
        if (deletedElementsId.length > 0) {
            for (const id of deletedElementsId) {
                if (type === 'textbox') {
                    if (slideData.textBoxIds.includes(id)) {
                        dispatch(slideAction.removeTextBox({ slideId: slideId, textBoxId: id }));
                        dispatch(slideAction.removeDeletedElementId(id));
                    }
                }
                else if (type === 'image') {
                    if (slideData.imageIds.includes(id)) {
                        dispatch(slideAction.removeImage({ slideId: slideId, imageId: id }));
                        dispatch(slideAction.removeDeletedElementId(id));
                    }
                }
                else if (type === 'shape') {
                    if (Object.keys(slideData.shapes).includes(id)) {
                        dispatch(slideAction.removeShape({ slideId: slideId, shapeId: id }));
                        dispatch(slideAction.removeDeletedElementId(id));
                    }
                };
            }
        }

        for (const elementId of masterIds) {
            const masterElement = masterData[elementId] || null;
            if (masterElement) {
                const elementData = {
                    style: masterElement.styles,
                    currentSlideId: slideId,
                };
                switch (type) {
                    case 'textbox':
                        elementData.delta = masterElement.delta;
                        elementData.textId = elementId;
                        if (elementId.includes('layout')) {
                            let layoutType = elementId.split('-')[0];
                            let matchingTextBoxIds = slideData.textBoxIds.filter(textBoxId => {
                                return textBoxId.includes(layoutType);
                            });
                            if (matchingTextBoxIds && matchingTextBoxIds.length > 0) {
                                let layoutBox = slideData.textBox?.[matchingTextBoxIds[0]] || null;
                                if (layoutBox) {
                                    const payload = {
                                        elementId: matchingTextBoxIds[0],
                                        elementData: {
                                            delta: {
                                                ops: [
                                                    {
                                                        insert: layoutBox.delta?.ops?.[0]?.insert,
                                                        attributes: {
                                                            // ...layoutBox.delta.ops[0].attributes,
                                                            ...masterElement?.delta?.ops?.[0]?.attributes
                                                        }
                                                    }
                                                ]
                                            },
                                            styles: { ...masterElement.styles, pointerEvents: 'all' },
                                        },
                                        slideId: slideId,
                                        type: type,
                                    };
                                    // dispatch(slideAction.updateElement(payload));
                                }
                            }
                        }
                        else {
                            if (!isExisit(slideData.textBoxIds, elementId)) {
                                dispatch(slideAction.addTextBox(elementData));
                            } else {
                                const payload = {
                                    elementId: elementId,
                                    elementData: {
                                        delta: masterElement.delta,
                                        styles: masterElement.styles,
                                    },
                                    slideId: slideId,
                                    type: type,
                                };
                                dispatch(slideAction.updateElement(payload));
                            }
                        }
                        break;
                    case 'image':
                        elementData.imageb64 = masterElement.imageb64;
                        elementData.imageId = elementId;
                        if (!isExisit(slideData.imageIds, elementId)) {
                            dispatch(slideAction.addImage(elementData));
                        } else {
                            const payload = {
                                elementId: elementId,
                                elementData: {
                                    imageb64: masterElement.imageb64,
                                    styles: masterElement.styles,
                                },
                                slideId: slideId,
                                type: type,
                            };
                            dispatch(slideAction.updateElement(payload));
                        }
                        break;
                    case 'shape':
                        elementData.delta = masterElement.delta;
                        elementData.shapeId = elementId;
                        if (!isExisit(Object.keys(slideData.shapes), elementId)) {
                            dispatch(slideAction.addShape(elementData));
                        } else {
                            const payload = {
                                elementId: elementId,
                                elementData: {
                                    delta: masterElement.delta,
                                    styles: masterElement.styles,
                                },
                                slideId: slideId,
                                type: type,
                            };
                            dispatch(slideAction.updateElement(payload));
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    }
    async function updateSlideBackground(slideId, slideData, backgroundColor) {
        const additionalData = slideData.additionalData || null;
        if (slideData.slideBackgroundColor !== backgroundColor && !additionalData?.isSlideColorChanged) {
            dispatch(slideAction.setSlideBackgroundColor({ slideId, backgroundColor }));
        }
    }

    function delay(t) {
        return new Promise(resolve => setTimeout(resolve, t));
    }
    const isExisit = (ids, elementId) => {
        const existingBoxIndex = ids.findIndex(id => id === elementId);
        if (existingBoxIndex === -1) {
            return false;
        }
        else if (existingBoxIndex >= 0) {
            return true;
        }
    }

    useEffect(() => {
        isProcessing && handleMasterSlideUpdate();
        if (!isProcessing && isProcessing !== null) {
            const data = {
                slides: existingSlides,
                mirrorSlides: mirrorSlides
            }
            workwiseServices.saveDocumentlastUpdate(id, dispatch, data);
        }
    }, [isProcessing]);

    useEffect(() => {
        const sidebarElement = sidebarRef.current;
        if (sidebarElement) {
            sidebarElement.scrollTop = scrollPositionRef.current;
        }
    }, [currentSlideId]);

    const handleScroll = () => {
        if (sidebarRef.current) {
            scrollPositionRef.current = sidebarRef.current.scrollTop;
        }
    };

    return (
        <div className='custom-modal' id='master-slide-container' ref={modalRef} key={currentSlideId}>
            <div className='modal-header'>
                <h2>Master Slide</h2>
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '25px', alignItems: 'center' }}>
                    {isProcessing ?
                        <Spin />
                        : (
                            <Button className='update-btn' onClick={(e) => updateAllSlides(e)}>Update</Button>
                        )}
                    <CloseOutlined className='close-icon' onClick={hideModal} />
                </div>
            </div>
            <Layout style={{ backgroundColor: 'white', height: '90%', overflow: 'hidden' }}>
                <Sider style={{
                    height: '100%',
                    background: 'white',
                    overflowY: 'scroll',
                    borderRight: 'thin solid #e3e5e8',
                }}
                    ref={sidebarRef}
                    onScroll={handleScroll}
                >
                    <MasterSlideSideBar selectedSlideLayout={selectedSlideLayout} setSelectedSlideLayout={setSelectedSlideLayout} />
                </Sider>
                <Content style={{ backgroundColor: '#f4f4f5', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100%', overflowY: 'scroll' }} >
                    <MasterSlideContainer />
                </Content>
            </Layout>
        </div>
    );
};

export default MasterSlide;