import React from 'react';
import { Skeleton, Space, Layout, Avatar } from 'antd';
import MyHeader from '../Header/Header.module.css';
import classes from "../Toolbar/Toolbar.module.css";
import classes2 from '../Slide/SpeakerNotes/speakerNotes.module.css';
import classesAvatar from '../Avatar/UserAvatar.module.css';

const Loader = () => {
    const { Sider, Content } = Layout;
    return (
        <>
            <div className={MyHeader.headerMain} >
                <Skeleton.Image active style={{ height: '35px', width: '35px' }} />
                <div className={MyHeader.headerCol}>
                    <Skeleton.Button active style={{ marginLeft: '2rem' }} />
                </div>
                <div className={MyHeader.titleBarButtons}>
                    <Skeleton.Button active />
                    <Skeleton.Image active style={{ height: '30px', width: '30px' }} />
                    <Avatar.Group className={classesAvatar.avatar}>
                        <Skeleton.Avatar active />
                        <Skeleton.Avatar active />
                        <Skeleton.Avatar active />
                    </Avatar.Group>
                </div>
            </div>
            <div className={classes.toolbar} >
                <Space>
                    <Skeleton.Button active style={{ height: '28px', width: '80px' }} />
                    <Skeleton.Button active style={{ height: '28px', width: '80px' }} />
                    <Skeleton.Button active style={{ height: '28px', width: '80px' }} />
                    <Skeleton.Button active style={{ height: '28px', width: '80px' }} />
                </Space>
            </div>
            <Layout>
                <Layout>
                    <Sider className="sidebar-container" width={window.screen.width < 992 ? '100%' : '14.6%'} >
                        {/* <Slides /> */}
                        <Skeleton.Button active style={{ height: '100px', width: '150px', marginTop: '25px', marginLeft: '30px' }} />
                        <Skeleton.Button active style={{ height: '100px', width: '150px', marginTop: '15px', marginLeft: '30px' }} />
                        <Skeleton.Button active style={{ height: '100px', width: '150px', marginTop: '15px', marginLeft: '30px' }} />
                        <Skeleton.Button active style={{ height: '100px', width: '150px', marginTop: '15px', marginLeft: '30px' }} />
                    </Sider>
                    <Content id="mainContainer" className='main-container' style={{ backgroundColor: '#f4f4f5', display: 'flex', justifyContent: 'center', paddingTop: '20px', minHeight: '85vh', overflowY: 'scroll' }}>
                        <span className="slide-container">
                        <Skeleton.Button className="slide-container" active style={{ width: '100%', height: '100%' }} />
                        </span>

                        {window.screen.width > 992 &&
                            <div className={classes2.speakerNotesContainer} >

                            </div>
                        }
                    </Content>
                </Layout>
            </Layout>
        </>

    )
}

export default Loader;
