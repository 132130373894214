import React from 'react';
import { Menu, Dropdown } from 'antd';
import { Tooltip } from 'antd';
import classes from './LayoutDropDown.module.css';
import layoutBlank from '../../../assets/Layouts/blank.png';
import layoutTitleBody from '../../../assets/Layouts/layout01.png';
import layoutTitleTwoColumns from '../../../assets/Layouts/layout02.png';
import layoutSectionHeader from '../../../assets/Layouts/layout03.png';
import layoutTitleOneColumn from '../../../assets/Layouts/layout04.png';
import layoutTitleOnly from '../../../assets/Layouts/layout05.png';
import LayoutEnum from '../../../utils/layoutEnum';

export const LayoutDropDown = ({ addSlideLayout }) => {
  const layouts = [
    { key: LayoutEnum.BLANK, name: 'Blank', image: layoutBlank },
    { key: LayoutEnum.TITLE_BODY, name: 'Title and body', image: layoutTitleBody },
    { key: LayoutEnum.TITLE_TWO_COLUMNS, name: 'Title and two columns', image: layoutTitleTwoColumns },
    { key: LayoutEnum.SECTION_HEADER, name: 'Section header', image: layoutSectionHeader },
    { key: LayoutEnum.TITLE_ONE_COLUMN, name: 'One column text', image: layoutTitleOneColumn },
    { key: LayoutEnum.TITLE_ONLY, name: 'Title Only', image: layoutTitleOnly },
  ];

  const menu = (
    <Menu mode="horizontal">
      <div className={classes['menu']}>
        {layouts.map(layout => (
          <Menu.Item key={layout.key} onClick={() => addSlideLayout(layout.key)} className={classes['menu-item']}>
            <div className={classes['img-div']}>
              <img src={layout.image} alt={layout.name} />
              <span>{layout.name}</span>
            </div>
          </Menu.Item>
        ))}
      </div>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Tooltip
        overlayInnerStyle={{
          width: 'fit-content',
          minHeight: '20px',
          fontSize: '10px',
          padding: '5px',
          textAlign: 'center',
          backgroundColor: 'black',
          lineHeight: '10px'
        }}
        placement="bottom"
        title={'Slide Layouts'}
      >
        <span className={classes['layout-dropdown']} onClick={e => e.preventDefault()}>
          Layouts
        </span>
      </Tooltip>
    </Dropdown>
  );
};
