import { useCallback, useEffect, useRef, useState } from "react";

const useContextMenu = () => {
  const elemRef = useRef();
  const [showMenu, setShowMenu] = useState({
    xPos: "0px",
    yPos: "0px",
    show: false,
    disable: false,
  });

  const handleContextMenu = (e) => {
    e.preventDefault();
    elemRef.current = e.target;
    if 
    (
        elemRef.current.id ||
        elemRef.current.getAttribute("data-id") ||
        elemRef.current.id.includes("title") ||
        elemRef.current.id.includes("Title") ||
        elemRef.current.id.includes("text") ||
        elemRef.current.id.includes("layout") ||
        elemRef.current.getAttribute("data-id") ||
        elemRef.current.id.includes("image") ||
        elemRef.current.parentElement.id.includes("image") ||
        elemRef.current.parentElement.id.includes("shape")
    ) {
      setShowMenu({
        xPos: `${e.clientX}px`,
        yPos: `${e.clientY}px`,
        show: true,
        disable: false,
      });
    } else {
      setShowMenu({
        xPos: `${e.clientX}px`,
        yPos: `${e.clientY}px`,
        show: true,
        disable: true,
      });
    }
  };

  const handleClick = useCallback(() => {
    showMenu.show && setShowMenu({ ...showMenu, show: false, disable: false });
  }, [showMenu.show]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  });

  return { showMenu, elemRef };
};

export default useContextMenu;
