import React from "react";
import { GiArrowCursor } from "react-icons/gi";
// import Avatar from "../../assets/avatar.jpg";
import "./styles.css";
import useRandomColor from "../hooks/useRandomColor";

const Mouse = ({ serverX, serverY,name }) => {
  const color = useRandomColor();
  const scrrenWidth=window.screen.width;    
  let userDetailsX = serverX-40;
  let userDetailsY = serverY-43;

  return (
     <>{name && <>
      <GiArrowCursor
        style={{
          color: color,
          position: "absolute",
          display:serverX<scrrenWidth?"flex":"none",
          left: serverX-50,
          top: serverY-28,
          zIndex:1000

        }}
      />
      <div
        style={{
          // backgroundColor:`#${color}`,
          // display: "flex",
          display:serverX<scrrenWidth?"flex":"none",
          alignItems: "center",
          justifyContent:"center",
          width: 'fit-content',
          height: "50px",
          position: "absolute",
          color: color,
          borderRadius: "3px",
          left: userDetailsX,
          top: userDetailsY,
          zIndex:1000

        }}
      >
        {/* <img src={Avatar} className="user-img" alt="user" /> */}
        <h6 style={{ color: `#${color}` }} className="user-name">{name||`User`}</h6>
      </div>
      </>}
    </>
  );
};

export default Mouse;
