import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getWorkWiseAuthToken } from "../utils/helper";
import Loader from "../components/SkeletonLoader/Loader";
import { slideAction } from "../../src/store/slideSlice";
import { useDispatch } from "react-redux";
import { workwiseServices } from "../components/WorkW-Api/workWiseApi";
export const AuthenticateDocument = ({ setIsLoading }) => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const handleAuhenticate = async () => {
    try {
      setIsLoading(true);
      if (!window.navigator.onLine) {
        // history.push('/no-internet');
        window.location.pathname="/no-internet"

        return;
      }
      else{
      if (!!getWorkWiseAuthToken()) {
        const response = await workwiseServices.getDocumentById(id);
        if (response?.status==200 && response.data?.data?.rightType !== 0 && response.data?.data) {
          setIsLoading(false);
          dispatch(slideAction.setDocumentInformation(response.data.data));
        }
        if(response.data?.data?.rightType == 0)
        {
          // history.push("/unauthorized")
          window.location.pathname="/unauthorized"
        }
        // history.push("/unauthorized")
      } 
      else {
        // history.push("/unauthorized")
        window.location.pathname="/unauthorized"
      }
      }
    } catch (error) {
      console.log({ error })
      if (error.response?.data?.responseCode === 1008) {
        // history.push("/invalid-document")
        window.location.pathname="/invalid-document"
      }
      else
      {
        // history.push("/unauthorized")
        window.location.pathname="/unauthorized"
      }
    }
  }
  useEffect(() => {
    handleAuhenticate();
    let info = JSON.parse(Buffer.from(getWorkWiseAuthToken()?.split?.('.')?.[1], 'base64').toString());
    dispatch(slideAction.setUserInformation(info));
  }, []);
  return <Loader/>
}