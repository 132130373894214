import React from 'react';

const Trapezoid = ({ fill, width, height, id }) => {
  const topWidth = width * 0.6; 
  const bottomWidth = width;
  const topOffset = (bottomWidth - topWidth) / 2;

  return (
    <svg
      id={id}
      width = '100%'
      height= '100%'
      viewBox={`0 0 ${width} ${height}`}
    >
      <polygon
       id={id}
        points={`0,${height} ${topOffset},0 ${topWidth + topOffset},${0} ${bottomWidth},${height}`}
        stroke="black" strokeWidth="2" fill={fill ? fill : "#fff"} />
    </svg>
  );
};

export default Trapezoid;
